import React from "react";
import styled from "styled-components";
import { hexToRGB } from "../../utils/style";
import { ErrorMessage } from "./ErrorMessage";
import { Typography } from "./Typography";
const Container = styled.div `
  display: flex;
  height: 44px;
`;
const TypographyWrapper = styled(Typography) `
  padding-top: 10px;
  padding-left: 10px;
`;
const StyledInput = styled.input `
  background-color: ${(props) => props.error
    ? hexToRGB(props.theme.colors.caution, 0.05)
    : props.theme.colors.inputFieldBackground};
  color: ${(props) => props.theme.colors.primaryText};
  font-size: ${(props) => props.theme.fontSize.small};
  padding: 13px 10px;
  border: 1px solid
    ${(props) => props.error
    ? props.theme.colors.caution
    : props.theme.colors.primaryBorder};
  border-radius: 6px;
  outline: 0;
  width: 100%;
`;
Input.defaultProps = {
    value: "",
    number: false,
    error: false,
    errorMessages: "",
    disabled: false,
    placeholder: "",
};
export function Input({ value, error, onChange, errorMessages, number, dataId, disabled, placeholder, suffix, }) {
    const handleChange = (event) => {
        number
            ? event.target.value
                .split("")
                .every((char) => !isNaN(parseInt(char, 10))) && onChange(event)
            : onChange(event);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, null,
            React.createElement(StyledInput, { value: value, error: error, onChange: handleChange, "data-id": dataId, disabled: disabled, placeholder: placeholder }),
            suffix && React.createElement(TypographyWrapper, null, suffix)),
        React.createElement(ErrorMessage, { errorMessage: errorMessages && errorMessages.find((message) => message !== "") })));
}
