import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { defaultTheme } from "../../themes";
import { Typography } from "../../atoms/Typography";
import { List, ListItem } from "../../atoms/List";
import { Accordion } from "../../organisms/Accordion";
import { MedicalInstitutionMemberList } from "../../organisms/MedicalInstitutionMemberList";
import { PageBody } from "../PageBody";
import { ClientCertificate as ClientCertificateContainer } from "../../../containers/organisms/ClientCertificate";
import { USER_ROLE } from "../../../utils";
const Menu = styled.div `
  padding: 10px 0;
  width: auto;
`;
const MenuInner = styled.div `
  cursor: pointer;
  padding: 0 30px;
  height: 50px;
`;
const MenuItem = styled.div `
  height: 100%;
  display: flex;
  align-items: center;
`;
const ContentWrapper = styled.div `
  padding: 0 40px;
`;
const Inner = styled.div `
  padding: 40px 0 20px;
  display: flex;
`;
const AccordionWrapper = styled.div `
  padding: 40px 0;
  display: flex;
  flex-flow: column;
`;
const LeftBlock = styled.div `
  flex: 1;
  padding-right: 40px;
`;
const MemberIconAndName = styled.div `
  display: flex;
  align-items: center;
  img {
    margin-right: 20px;
  }
`;
const RightBlock = styled.div `
  flex: 1;
  padding-left: 40px;
`;
const PaddingRightWrapper = styled.div `
  width: 100%;
  padding-right: 20px;
`;
const TextRightTypography = (props) => (React.createElement(PaddingRightWrapper, null,
    React.createElement(Typography, { textRight: true, size: 2 }, props.children)));
export function MedicalInstitutionDetailPage({ medicalInstitution, medicalInstitutionMembers, refetchMedicalInstitutionMembers, onResendWelcomeMailButtonClick, clientCertificate, userData, }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    return (React.createElement(React.Fragment, null,
        React.createElement(PageBody, { title: "\u533B\u7642\u6A5F\u95A2\u8A73\u7D30", buttonTitle: "\u30E1\u30CB\u30E5\u30FC", buttonIcon: React.createElement(FontAwesomeIcon, { icon: "pen", color: defaultTheme.colors.brand }), onButtonClick: () => {
                setIsMenuOpen(!isMenuOpen);
            }, menuListVisible: isMenuOpen, menuList: React.createElement(Menu, null,
                React.createElement(MenuInner, null,
                    React.createElement(MenuItem, { onClick: onResendWelcomeMailButtonClick },
                        React.createElement(Typography, { size: 2 }, "\u30A6\u30A7\u30EB\u30AB\u30E0\u30E1\u30FC\u30EB\u306E\u518D\u9001")))), onHideMenu: () => {
                setIsMenuOpen(false);
            } },
            React.createElement(React.Fragment, null,
                React.createElement(ContentWrapper, null,
                    React.createElement(Inner, null,
                        React.createElement(LeftBlock, null,
                            React.createElement(MemberIconAndName, null,
                                React.createElement("img", { src: medicalInstitution.memberImage, width: "72", height: "72", alt: "user_icon" }),
                                React.createElement(Typography, { size: 5, breakWord: true }, medicalInstitution.name))))),
                React.createElement(ContentWrapper, null,
                    React.createElement(Inner, null,
                        React.createElement(LeftBlock, null,
                            React.createElement(List, null,
                                React.createElement(ListItem, null,
                                    React.createElement(Typography, { bold: true, size: 1 }, "id"),
                                    React.createElement(TextRightTypography, null, medicalInstitution.id)),
                                React.createElement(ListItem, null,
                                    React.createElement(Typography, { bold: true, size: 1 }, "\u96FB\u8A71\u756A\u53F7"),
                                    React.createElement(TextRightTypography, null, medicalInstitution.phoneNumber)),
                                React.createElement(ListItem, null,
                                    React.createElement(Typography, { bold: true, size: 1 }, "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9"),
                                    React.createElement(TextRightTypography, null, medicalInstitution.mailAddress)),
                                React.createElement(ListItem, null,
                                    React.createElement(Typography, { bold: true, size: 1 }, "\u30BB\u30C3\u30C8\u30A2\u30C3\u30D7\u72B6\u614B"),
                                    React.createElement(TextRightTypography, null, medicalInstitution.isSetupFinished ? "完了" : "未完了")))),
                        React.createElement(RightBlock, null,
                            React.createElement(List, null,
                                React.createElement(ListItem, null,
                                    React.createElement(Typography, { bold: true, size: 1 }, "\u90F5\u4FBF\u756A\u53F7"),
                                    React.createElement(TextRightTypography, null, medicalInstitution.postalCode)),
                                React.createElement(ListItem, null,
                                    React.createElement(Typography, { bold: true, size: 1 }, "\u4F4F\u6240"),
                                    React.createElement(TextRightTypography, null,
                                        medicalInstitution.prefecture,
                                        medicalInstitution.city,
                                        medicalInstitution.otherAddress)),
                                React.createElement(ListItem, null,
                                    React.createElement(Typography, { bold: true, size: 1 }, "\u4EE3\u8868\u8005\u540D"),
                                    React.createElement(TextRightTypography, null, medicalInstitution.representativeName)),
                                React.createElement(ListItem, null,
                                    React.createElement(Typography, { bold: true, size: 1 }, "APS URL"),
                                    React.createElement(TextRightTypography, null, medicalInstitution.apsUrl)))))),
                (userData === null || userData === void 0 ? void 0 : userData.userRole) === USER_ROLE.CS_USER && (React.createElement(ContentWrapper, null,
                    React.createElement(AccordionWrapper, null,
                        React.createElement(Accordion, { title: "クライアント証明書" },
                            React.createElement(ClientCertificateContainer, { clientCertificate: clientCertificate }))))),
                React.createElement(ContentWrapper, null,
                    React.createElement(AccordionWrapper, null,
                        React.createElement(Accordion, { title: "医療機関メンバー" },
                            React.createElement(MedicalInstitutionMemberList, { medicalInstitutionMembers: medicalInstitutionMembers, refetchMedicalInstitutionMembers: refetchMedicalInstitutionMembers }))))))));
}
