import { useMutation, gql } from "@apollo/client";
import { DeliveryOrderFragments } from "./queries";
/**
 * Order Delivery
 */
export const CREATE_ORDER_DELIVERY = gql `
  mutation createDeliveryOrder($input: CreateDeliveryOrderInput!) {
    createDeliveryOrder(input: $input) {
      ...DeliveryOrderDetails
    }
  }
  ${DeliveryOrderFragments.details}
`;
/**
 * Reissue Secret Code
 */
export const REISSUE_SECRET_CODE = gql `
  mutation reissueSecretCode($medicalInstitutionMemberId: ID!) {
    reissueSecretCode(medicalInstitutionMemberId: $medicalInstitutionMemberId) {
      secretCode
    }
  }
`;
export const ReissueSecretCodeMutation = (baseOptions) => useMutation(REISSUE_SECRET_CODE, baseOptions);
/**
 * Resend Welcome Mail
 */
export const RESEND_SETUP_MAIL = gql `
  mutation resendSetupEmail($input: ResendSetupEmailInput!) {
    resendSetupEmail(input: $input) {
      recipientEmailAddress
    }
  }
`;
export const RESEND_SETUP_REGISTERED_MAIL = gql `
  mutation resendSetupRegisteredEmail(
    $input: ResendSetupRegisteredEmailInput!
  ) {
    resendSetupRegisteredEmail(input: $input) {
      recipientEmailAddress
    }
  }
`;
export const CREATE_PDT_DOCUMENT = gql `
  mutation createPdtDocument($input: CreatePdtDocumentInput!) {
    createPdtDocument(input: $input) {
      id
      name
    }
  }
`;
/**
 * PDT資材をストレージにアップロードしてからpdtDocumentを作成する
 */
export const UPLOAD_PDT_DOCUMENT = gql `
  mutation uploadPdtDocument($input: UploadPdtDocumentInput!) {
    uploadPdtDocument(input: $input) {
      id
      name
      url
    }
  }
`;
/**
 * HT患者のメールアドレスを更新する
 */
export const UPDATE_HT_USER_EMAIL_ADDRESS = gql `
  mutation updateHtUserEmailAddress($input: UpdateHtUserEmailAddressInput!) {
    updateHtUserEmailAddress(input: $input) {
      id
      prescriptionCode
      prescriptionCodeId
      prescribedAt
      status
      nickname
      appName
      pdtCode
      pdtId
      birthday
      medicalInstitutionId
      medicalInstitutionName
      email
      createdAt
      bloodPressureMeasurementRate
    }
  }
`;
/**
 * HT患者のパスワードを更新する
 */
export const UPDATE_HT_USER_PASSWORD = gql `
  mutation updateHtUserPassword($input: UpdateHtUserPasswordInput!) {
    updateHtUserPassword(input: $input) {
      id
    }
  }
`;
/**
 * HT患者の確認番号を発行する
 */
export const PUBLISH_HT_VERIFY_CODE = gql `
  mutation publishHtVerifyCode($input: VerifyCodeInput!) {
    publishHtVerifyCode(input: $input) {
      code
      permanent
    }
  }
`;
export const FORCE_CANCEL_PRESCRIPTION = gql `
  mutation forceCancelPrescription($input: ForceCancelPrescriptionInput!) {
    forceCancelPrescription(input: $input) {
      ... on ForceCancelPrescriptionError {
        message
        code
        prescriptionCode
      }
      ... on Prescription {
        id
        prescriptionCureAppPdtCode {
          id
          status
        }
      }
    }
  }
`;
