export const createAppMutations = (appVar) => {
    const showHeader = () => appVar({ ...appVar(), isHeaderVisible: true });
    const hideHeader = () => appVar({ ...appVar(), isHeaderVisible: false });
    const showMenuList = () => appVar({ ...appVar(), isMenuListVisible: true });
    const hideMenuList = () => appVar({ ...appVar(), isMenuListVisible: false });
    const showSnackBar = (text) => appVar({ ...appVar(), snackbar: { isOpen: true, text } });
    const hideSnackBar = () => appVar({ ...appVar(), snackbar: { isOpen: false, text: null } });
    const enableScroll = () => (document.body.style.overflowY = "visible");
    const disableScroll = () => {
        document.body.style.overflowY = "hidden";
        scrollTo({ top: 0, behavior: "smooth" });
    };
    const setNotFoundStatus = (isNotFound) => appVar({ ...appVar(), isNotFound });
    const setForbiddenStatus = (isForbidden) => appVar({ ...appVar(), isForbidden });
    const setServerErrorStatus = (isServerError) => appVar({ ...appVar(), isServerError });
    const setNotSameSubDomainStatus = (isNotSameSubDomain) => appVar({ ...appVar(), isNotSameSubDomain });
    return {
        showHeader,
        hideHeader,
        showMenuList,
        hideMenuList,
        showSnackBar,
        hideSnackBar,
        enableScroll,
        disableScroll,
        setNotFoundStatus,
        setForbiddenStatus,
        setServerErrorStatus,
        setNotSameSubDomainStatus,
    };
};
