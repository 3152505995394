import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconTextButton } from "../../atoms/IconTextButton";
import { defaultTheme } from "../../themes";
const Container = styled.div `
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 110px;
`;
const GoBackBlock = styled.div `
  position: absolute;
  top: 20px;
  left: 20px;
`;
export function AuthBase({ hasBackButton, onBackButtonClick, children, }) {
    return (React.createElement(Container, null,
        hasBackButton && (React.createElement(GoBackBlock, null,
            React.createElement(IconTextButton, { text: "アカウント選択画面に戻る", icon: React.createElement(FontAwesomeIcon, { icon: "arrow-left", color: defaultTheme.colors.brand }), onButtonClick: onBackButtonClick }))),
        children));
}
AuthBase.defaultProps = {
    hasBackButton: false,
};
