// 色々なコンポーネントで使い回されそうな色は、ここに定義する。
const colors = {
    brand: "#10626C", // changed
    primaryText: "#4E5E74",
    secondaryText: "#ffffff",
    primaryBackground: "#ffffff",
    secondaryBackground: "#4E5E74",
    inputFieldBackground: "#FBFCFD",
    hoverBackground: "#F6FCFF",
    pageBodyBackground: "#F1F3F7",
    disabledBackground: "#A4AEBA",
    primaryBorder: "#C6C6C6", //changed
    secondaryBorder: "#D6DDE8",
    primaryShadow: "#0000000A",
    embossShadow: "#0000001A",
    good: "#a6a6a6",
    caution: "#DD4B5B",
    ordinary: "#FFA000",
};
const fonts = {
    sanSerif: `'游ゴシック体', YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif`,
};
const fontSize = {
    xsmall: `14px`,
    small: `16px`,
    medium: `18px`,
    large: `20px`,
    xlarge: `26px`,
    xxlarge: `28px`,
};
const interactions = {
    primaryEasing: "ease-in-out",
    primaryDuration: "0.6s",
};
export const defaultTheme = { colors, fonts, fontSize, interactions };
