import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PageBody } from "../PageBody";
import { Typography } from "../../atoms/Typography";
import { defaultTheme } from "../../themes";
import { Label } from "../../atoms/Label";
const ContentWrapper = styled.div `
  padding: 0 40px;
  clear: both;
`;
const Container = styled.div `
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
`;
const InputWrapper = styled.div `
  display: flex;
  width: 100%;
`;
const LeftBlock = styled.div `
  padding: 15px 0 0 0;
  width: 85%;
`;
const RightBlock = styled.div `
  flex: 1;
  padding: 15px 0 0 0;
`;
const RowWrapper = styled.div `
  min-height: 58px;
  padding: 10px 5px 10px 0;
  ${({ theme }) => `border-bottom: 1px solid ${theme.colors.primaryBorder};`}
  p {
    margin-top: 10px;
  }
`;
const SomeItemRowWrapper = styled(RowWrapper) `
  display: flex;
`;
const DeliveryItemRowWrapper = styled(RowWrapper) `
  border-bottom: none;
`;
const ItemWrapper = styled.div `
  width: ${(props) => props.width || "30%"};
  margin-right: 15px;
`;
const DeliveryItemWrapper = styled.div `
  display: flex;
  padding: 10px 5px 10px 0;
  ${({ theme }) => `border-bottom: 1px solid ${theme.colors.primaryBorder};`}

  &:last-child {
    border: 0;
  }
`;
export const CompleteDeliveryOrderPage = ({ deliveryOrder, onDeliveryOrderCompleteClick, }) => {
    var _a;
    return (React.createElement(React.Fragment, null,
        React.createElement(PageBody, { title: "発送依頼を行いました", buttonTitle: "\u5B8C\u4E86", buttonOutline: true, buttonIcon: React.createElement(FontAwesomeIcon, { icon: "check", color: defaultTheme.colors.brand }), onButtonClick: () => {
                onDeliveryOrderCompleteClick();
            } },
            React.createElement(Container, null,
                React.createElement(ContentWrapper, null,
                    React.createElement(RowWrapper, null,
                        React.createElement(Label, { text: "宛名" }),
                        React.createElement(InputWrapper, null,
                            React.createElement(Typography, { size: 2 }, deliveryOrder.name))),
                    React.createElement(SomeItemRowWrapper, null,
                        React.createElement(ItemWrapper, { width: "10%" },
                            React.createElement(Label, { text: "郵便番号" }),
                            React.createElement(InputWrapper, null,
                                React.createElement(Typography, { size: 2 }, deliveryOrder.postalCode))),
                        React.createElement(ItemWrapper, { width: "50%" },
                            React.createElement(Label, { text: "配送先住所" }),
                            React.createElement(InputWrapper, null,
                                React.createElement(Typography, { size: 2 }, deliveryOrder.address))),
                        React.createElement(ItemWrapper, { width: "10%" },
                            React.createElement(Label, { text: "電話番号" }),
                            React.createElement(InputWrapper, null,
                                React.createElement(Typography, { size: 2 }, deliveryOrder.phoneNumber)))),
                    React.createElement(SomeItemRowWrapper, null,
                        React.createElement(ItemWrapper, { width: "10%" },
                            React.createElement(Label, { text: "配送指定日" }),
                            React.createElement(InputWrapper, null,
                                React.createElement(Typography, { size: 2 }, deliveryOrder.deliveryDate || "-"))),
                        React.createElement(ItemWrapper, { width: "50%" },
                            React.createElement(Label, { text: "配送時間帯" }),
                            React.createElement(InputWrapper, null,
                                React.createElement(Typography, { size: 2 }, (_a = deliveryOrder.deliveryTime) === null || _a === void 0 ? void 0 : _a.section))),
                        React.createElement(ItemWrapper, { width: "20%" },
                            React.createElement(Label, { text: "注文クラス" }),
                            React.createElement(InputWrapper, null,
                                React.createElement(Typography, { size: 2 }, deliveryOrder.orderClass)))),
                    React.createElement(DeliveryItemRowWrapper, null,
                        React.createElement(Label, { text: "\u914D\u9001\u7269" }),
                        deliveryOrder.deliveryProducts
                            ? deliveryOrder.deliveryProducts.map((deliveryProduct, index) => (React.createElement(DeliveryItemWrapper, { key: index },
                                React.createElement(LeftBlock, null, `${deliveryProduct.product.name} : ${deliveryProduct.product.description}`),
                                React.createElement(RightBlock, null,
                                    deliveryProduct.quantity,
                                    "\u500B"))))
                            : ""))))));
};
