import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { emailRegex, alphanumericSymbolRegex, numericRegex, } from "../utils/regex";
const checkType = (value, is) => {
    const isString = () => checkType(value, is || typeof value === "string");
    const isNumber = () => checkType(value, is || typeof value === "number");
    const isBoolean = () => checkType(value, is || typeof value === "boolean");
    const isUndefined = () => checkType(value, is || typeof value === "undefined");
    return {
        isString,
        isNumber,
        isBoolean,
        isUndefined,
        is,
    };
};
export const useValidation = (validationSet, deps, alreadyValueSet) => {
    const validationSetKeys = Object.keys(validationSet);
    const validate = (formTouched, validationSetProp) => {
        const formValue = validationSetProp.value;
        const validateCommon = () => {
            if (formTouched &&
                (typeof formValue === "string" ||
                    typeof formValue === "number" ||
                    typeof formValue === "undefined")) {
                const rules = validationSetProp.rules;
                return [
                    validateMaxLength(rules.maxLength, formValue),
                    validateMinLength(rules.minLength, formValue),
                    validateSpecificLength(rules.specificLength, formValue),
                    validateInputRequired(rules.inputRequired, formValue),
                    validateSelectRequired(rules.selectRequired, formValue),
                    validateNotIncludes(rules.notIncludes, formValue),
                ];
            }
            return [];
        };
        const validateString = () => {
            if (formTouched && typeof formValue === "string") {
                const rules = validationSetProp.rules;
                return [
                    validateAbleToConvertNumber(rules.ableToConvertNumber, formValue),
                    validateEmailFormat(rules.email, formValue),
                    validateIsAlphanumericSymbol(rules.isAlphanumericSymbol, formValue),
                    validateIsNumeric(rules.isNumeric, formValue),
                ];
            }
            return [{ isValid: true, message: undefined }];
        };
        const validateNumber = () => {
            if (formTouched && typeof formValue === "number") {
                const rules = validationSetProp.rules;
                return [
                    validateMaxValue(rules.maxValue, formValue),
                    validateMinValue(rules.minValue, formValue),
                ];
            }
            return [];
        };
        const validateCustom = () => {
            const customValidateResults = [];
            if (formTouched &&
                !checkType(formValue).isString().isNumber().isUndefined().isBoolean().is) {
                if ("checkDateSetValidity" in validationSetProp.rules) {
                    // @ts-ignore
                    const { year, month, date } = formValue;
                    customValidateResults.push(validateDateValidity(year, month, date));
                }
                if ("isBeforeNow" in validationSetProp.rules) {
                    // @ts-ignore
                    const { year, month, date } = formValue;
                    customValidateResults.push(validateDateBefore(year, month, date));
                }
            }
            if (formTouched &&
                !checkType(formValue).isString().isNumber().isBoolean().is &&
                "fileRequired" in validationSetProp.rules) {
                const file = formValue;
                customValidateResults.push(validateFile(file));
            }
            return customValidateResults;
        };
        const validateResults = [
            ...validateCommon(),
            ...validateString(),
            ...validateNumber(),
            ...validateCustom(),
        ];
        return {
            isValid: validateResults.every((validateResult) => validateResult.isValid),
            message: validateResults
                .map((validateResult) => validateResult.message)
                .filter((message) => message !== undefined),
        };
    };
    const validateMaxLength = (maxLength, value) => {
        if (maxLength !== undefined &&
            value !== undefined &&
            value.toString().length > maxLength)
            return {
                isValid: false,
                message: `${maxLength}文字以下で入力してください`,
            };
        return { isValid: true, message: undefined };
    };
    const validateMinLength = (minLength, value) => {
        if (minLength !== undefined &&
            value !== undefined &&
            value.toString().length < minLength)
            return {
                isValid: false,
                message: `${minLength}文字以上で入力してください`,
            };
        return { isValid: true, message: undefined };
    };
    const validateInputRequired = (inputRequired, value) => {
        if (inputRequired && (value === undefined || value.toString() === ""))
            return {
                isValid: false,
                message: "入力してください",
            };
        return { isValid: true, message: undefined };
    };
    const validateSelectRequired = (selectRequired, value) => {
        if (selectRequired && (value === undefined || value === ""))
            return {
                isValid: false,
                message: "選択してください",
            };
        return { isValid: true, message: undefined };
    };
    const validateMaxValue = (maxValue, value) => {
        if (maxValue !== undefined && value !== undefined && value > maxValue)
            return {
                isValid: false,
                message: `${maxValue}以下で入力してください`,
            };
        return { isValid: true, message: undefined };
    };
    const validateMinValue = (minValue, value) => {
        if (minValue !== undefined && value !== undefined && value < minValue)
            return {
                isValid: false,
                message: `${minValue}以上で入力してください`,
            };
        return { isValid: true, message: undefined };
    };
    const validateAbleToConvertNumber = (ableToConvertNumber, value) => {
        if (ableToConvertNumber &&
            value !== undefined &&
            isNaN(parseInt(value, 10))) {
            return {
                isValid: false,
                message: "数値のみを入力してください",
            };
        }
        return {
            isValid: true,
            message: undefined,
        };
    };
    const validateDateValidity = (year, month, date) => {
        const isValidDate = dayjs(`${year}-${month}-${date}`).format("YYYYMD") ===
            `${year}${month}${date}`;
        if (!isValidDate)
            return {
                isValid: false,
                message: "正しい日付を入力してください",
            };
        return {
            isValid: true,
            message: undefined,
        };
    };
    const validateFile = (file) => {
        if (!(file === null || file === void 0 ? void 0 : file.name)) {
            return {
                isValid: false,
                message: "ファイルが指定されていません",
            };
        }
        return {
            isValid: true,
            message: undefined,
        };
    };
    const validateDateBefore = (year, month, date) => {
        if (!dayjs(`${year}-${month}-${date}`).isBefore(dayjs(), "date"))
            return {
                isValid: false,
                message: "現在より過去の日付を入力してください",
            };
        return {
            isValid: true,
            message: undefined,
        };
    };
    const validateEmailFormat = (isEmail, value) => {
        if (isEmail && value !== undefined && !emailRegex.test(value)) {
            return {
                isValid: false,
                message: "正しいメールアドレスを入力してください",
            };
        }
        return {
            isValid: true,
            message: undefined,
        };
    };
    const validateSpecificLength = (specificLength, value) => {
        if (specificLength !== undefined &&
            value !== undefined &&
            value.toString().length !== specificLength)
            return {
                isValid: false,
                message: `${specificLength}文字で入力してください`,
            };
        return { isValid: true, message: undefined };
    };
    const validateIsAlphanumericSymbol = (isAlphanumericSymbol, value) => {
        if (isAlphanumericSymbol &&
            value !== undefined &&
            !alphanumericSymbolRegex.test(value.toString()))
            return {
                isValid: false,
                message: `半角英数記号のみを入力してください`,
            };
        return { isValid: true, message: undefined };
    };
    const validateIsNumeric = (isNumericSymbol, value) => {
        if (isNumericSymbol &&
            value !== undefined &&
            !numericRegex.test(value.toString()))
            return {
                isValid: false,
                message: `半角英数記号のみを入力してください`,
            };
        return { isValid: true, message: undefined };
    };
    const validateNotIncludes = (notIncludesValues, value) => {
        if (notIncludesValues &&
            value !== undefined &&
            notIncludesValues.includes(value.toString()))
            return {
                isValid: false,
                // TODO: ここだけ氏名の重複に限ったエラーメッセージになっているので
                // 他の箇所で使うことがあればエラーメッセージをhooksの引数で渡せるようにしたい
                message: `登録済みの氏名と重複しています`,
            };
        return { isValid: true, message: undefined };
    };
    const [touched, setTouched] = useState(validationSetKeys.reduce((acc, key) => ({ ...acc, [key]: !!alreadyValueSet }), {}));
    const [errors, setErrors] = useState(validationSetKeys.reduce((acc, key) => ({
        ...acc,
        [key]: validate(touched[key], validationSet[key]),
    }), {}));
    const initializeState = () => {
        setTouched(validationSetKeys.reduce((acc, key) => ({ ...acc, [key]: false }), {}));
        setErrors(validationSetKeys.reduce((acc, key) => ({
            ...acc,
            [key]: validate(touched[key], validationSet[key]),
        }), {}));
    };
    const validateAll = (ignoreTouched) => validationSetKeys.reduce((acc, key) => ({
        ...acc,
        [key]: validate(ignoreTouched ? true : touched[key], validationSet[key]),
    }), {});
    useEffect(() => {
        setErrors(validateAll());
        // validationSetをdepsに含めると無限に再renderされるため
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps, touched]);
    return {
        errors,
        isFormValid: Object.values(validateAll(true)).every((error) => error.isValid),
        setTouched: (key) => setTouched((state) => ({ ...state, [key]: true })),
        setTouchedWrapper: (callBack) => (setTouchedKey, ...parameters) => {
            setTouched((state) => ({ ...state, [setTouchedKey]: true }));
            if (typeof callBack === "function") {
                callBack(...parameters);
            }
        },
        resetState: initializeState,
    };
};
