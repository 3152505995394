/**
 * CONTEXT MODELS
 */
export const initialState = {
    isHeaderVisible: true,
    isMenuListVisible: true,
    snackbar: {
        isOpen: false,
        text: null,
    },
    isNotFound: false,
    isForbidden: false,
    isServerError: false,
    isNotSameSubDomain: false,
};
