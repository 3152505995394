import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_USER_STATE } from "../graphql/queries";
import { USER_ROLE } from "../utils/constants";
export const useCheckUserRole = () => {
    const history = useHistory();
    const { data: userData } = useQuery(GET_USER_STATE);
    useEffect(() => {
        if ((userData === null || userData === void 0 ? void 0 : userData.userState.userRole) !== USER_ROLE.CS_USER) {
            history.replace("/");
        }
    }, [userData === null || userData === void 0 ? void 0 : userData.userState, history]);
};
