import React from "react";
import styled from "styled-components";
import { Modal } from "../../atoms/Modal";
import { Button } from "../../atoms/Button";
import { Typography } from "../../atoms/Typography";
const StyledModal = styled(Modal) `
  overflow: visible;
`;
const ModalContainer = styled.div `
  min-width: 480px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  min-height: 300px;
  position: relative;
`;
const Container = styled.div `
  width: 100%;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: start;
`;
const TitleWrapper = styled.div `
  margin: 60px 0 20px 0;
  width: 100%;
  text-align: center;
  line-height: 28px;
`;
const ButtonWrapper = styled.div `
  padding: 0 20px;
  display: flex;
  width: 100%;
  height: 44px;
  margin-bottom: 40px;
  button {
    margin-right: 20px;
  }
  button:last-child {
    margin-right: 0;
  }
`;
const InputWrapper = styled.div `
  width: 100%;
  padding: 0 20px;
  margin: 30px 0 50px 0;
  display: flex;
  justify-content: center;
`;
export const ConfirmModal = ({ isOpen, title, actionLabel, cancelLabel, value, isLoading, closeModal, onChangeClick, }) => {
    return (React.createElement(StyledModal, { isOpen: isOpen },
        React.createElement(ModalContainer, null,
            React.createElement(Container, null,
                React.createElement(TitleWrapper, null,
                    React.createElement(Typography, { size: 4, bold: true }, title || "")),
                React.createElement(InputWrapper, null,
                    React.createElement(Typography, { size: 2, bold: true }, value)),
                React.createElement(ButtonWrapper, null,
                    React.createElement(Button, { onClick: closeModal, disabled: isLoading },
                        React.createElement(Typography, { size: 2, bold: true }, cancelLabel)),
                    React.createElement(Button, { buttonType: "primary", onClick: onChangeClick, disabled: isLoading },
                        React.createElement(Typography, { size: 2, bold: true }, actionLabel)))))));
};
