import debug from "debug";
import { captureMessage, captureException } from "@sentry/react";
/**
 * このファイルの外で好き勝手なプレフィックスのロガーが作られて治安が悪くならないようにこの関数はexportしない
 *
 * ファイル下部の方で事前にプレフィックスとロガーの名前を定義しておき、それを使うようにする
 */
const createLogger = (suffix = "") => debug(`aps:frontend${suffix ? `:${suffix}` : ""}`);
/**
 * エラー向けのlogger（Sentryにデータを送信）
 */
const createErrorLogger = (level) => {
    return (err) => {
        if (typeof err === "string") {
            captureMessage(err, level);
        }
        else {
            captureException(err);
        }
        createLogger(level)(err);
    };
};
/**
 * 簡潔な情報のログ（詳細な場合はverbose）
 */
export const infoLogger = createLogger("info");
/**
 * 致命的エラーのログ
 */
export const fatalLogger = createErrorLogger("fatal");
/**
 * 実行時エラーのログ
 */
export const errorLogger = createErrorLogger("error");
/**
 * 警告のログ
 */
export const warnLogger = createErrorLogger("warning");
