import React from "react";
import styled from "styled-components";
import { Typography } from "../atoms/Typography";
import { DataTable, DataListSimpleHeader, DataListBody, DataListRow, DataListBodyItem, } from "../atoms/Table";
import { ReissueSecretCodeButton } from "./ReissueSecretCodeButton";
const ReissueSecretCodeButtonWrapper = styled.div `
  width: 200px;
  height: 30px;
  margin-left: auto;
`;
export function MedicalInstitutionMemberList({ medicalInstitutionMembers, refetchMedicalInstitutionMembers, }) {
    const flexRatio = [3, 2, 2, 2, 2];
    const headerTitles = ["名前", "職位", "権限", "ステータス", ""];
    const items = medicalInstitutionMembers.map((medicalInstitutionMember, index) => {
        return (React.createElement(DataListRow, { flexRatio: flexRatio, key: index },
            React.createElement(DataListBodyItem, null,
                React.createElement(Typography, { size: 2, withEllipsis: true }, medicalInstitutionMember.name)),
            React.createElement(DataListBodyItem, null,
                React.createElement(Typography, { size: 2, withEllipsis: true }, medicalInstitutionMember.position.join("、"))),
            React.createElement(DataListBodyItem, null,
                React.createElement(Typography, { size: 2, withEllipsis: true }, medicalInstitutionMember.role.join("、"))),
            React.createElement(DataListBodyItem, null,
                React.createElement(Typography, { size: 2 }, medicalInstitutionMember.status)),
            React.createElement(DataListBodyItem, null,
                React.createElement(ReissueSecretCodeButtonWrapper, null,
                    React.createElement(ReissueSecretCodeButton, { medicalInstitutionMemberId: medicalInstitutionMember.id, medicalInstitutionMemberName: medicalInstitutionMember.name, medicalInstitutionMemberStatus: medicalInstitutionMember.status, refetchMedicalInstitutionMembers: refetchMedicalInstitutionMembers })))));
    });
    return (React.createElement(DataTable, null,
        React.createElement(DataListSimpleHeader, { titles: headerTitles, flexRatio: flexRatio }),
        React.createElement(DataListBody, { rows: items })));
}
