import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { defaultTheme } from "../../themes";
import { PageBody } from "../PageBody";
import { Typography } from "../../atoms/Typography";
import { List, ListItem } from "../../atoms/List";
import { hexToRGB } from "../../../utils/style";
import { Snackbar } from "../../atoms/Snackbar";
import okIcon from "../../../assets/icons/ok.png";
import errorIcon from "../../../assets/icons/alert.png";
import { StatusLabelMap } from "../../../utils/prescription";
const Menu = styled.div `
  padding: 10px 0;
  width: auto;
`;
const MenuInner = styled.div `
  cursor: pointer;
  padding: 0 30px;
  display: flex;
  flex-flow: column nowrap;
`;
const MenuItem = styled.div `
  margin: 15px 0;
  display: flex;
  align-items: center;
`;
const MenuLink = styled.a `
  margin: 15px 0;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${defaultTheme.colors.primaryText};
`;
const ContentWrapper = styled.div `
  padding: 0 40px;
`;
const Inner = styled.div `
  padding: 40px 0 20px;
  display: flex;
  ${(props) => hexToRGB(props.theme.colors.primaryBorder, 0.5)};
`;
const LeftBlock = styled.div `
  flex: 1;
  padding-right: 40px;
`;
const MemberIconAndName = styled.div `
  display: flex;
  align-items: center;
  img {
    margin-right: 20px;
  }
`;
const RightBlock = styled.div `
  flex: 1;
  padding-left: 40px;
`;
const PaddingRightWrapper = styled.div `
  width: 100%;
  padding-right: 20px;
`;
const StyledLink = styled(Link) `
  color: ${(props) => props.theme.colors.brand};
`;
const TextRightTypography = (props) => (React.createElement(PaddingRightWrapper, null,
    React.createElement(Typography, { textRight: true, size: 2 }, props.children)));
export function PatientHtDetailPage({ patientHt, onUpdateEmailAddressButtonClick, onPublishVerifyCodeButtonClick, onCancelPrescriptionCodeButtonClick, maskedDetail, snackBar, }) {
    var _a, _b;
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const maskedDetailBlob = new Blob([JSON.stringify(maskedDetail)], {
        type: "text/json",
    });
    const jsonURL = URL.createObjectURL(maskedDetailBlob);
    return (React.createElement(React.Fragment, null,
        React.createElement(PageBody, { title: "\u51E6\u65B9\u8A73\u7D30", buttonTitle: "\u30E1\u30CB\u30E5\u30FC", buttonIcon: React.createElement(FontAwesomeIcon, { icon: "pen", color: defaultTheme.colors.brand }), onButtonClick: () => {
                setIsMenuOpen(!isMenuOpen);
            }, menuListVisible: isMenuOpen, menuList: React.createElement(Menu, null,
                React.createElement(MenuInner, null,
                    React.createElement(MenuItem, { onClick: onUpdateEmailAddressButtonClick },
                        React.createElement(Typography, { size: 2 }, "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u306E\u5909\u66F4")),
                    React.createElement(MenuItem, { onClick: onPublishVerifyCodeButtonClick },
                        React.createElement(Typography, { size: 2 }, "\u78BA\u8A8D\u756A\u53F7\u306E\u767A\u884C")),
                    React.createElement(MenuItem, { onClick: onCancelPrescriptionCodeButtonClick },
                        React.createElement(Typography, { size: 2 }, "\u51E6\u65B9\u306E\u30AD\u30E3\u30F3\u30BB\u30EB")),
                    React.createElement(MenuLink, { href: jsonURL, download: "maskedDetail.json" },
                        React.createElement(Typography, { size: 2 }, "json\u30D5\u30A1\u30A4\u30EB\u51FA\u529B")))), onHideMenu: () => {
                setIsMenuOpen(false);
            } },
            React.createElement(React.Fragment, null,
                React.createElement(ContentWrapper, null,
                    React.createElement(Inner, null,
                        React.createElement(LeftBlock, null,
                            React.createElement(MemberIconAndName, null,
                                React.createElement("img", { src: patientHt.memberImage, width: "72", height: "72", alt: "user_icon" }),
                                React.createElement(Typography, { size: 5, breakWord: true }, patientHt.name))))),
                React.createElement(ContentWrapper, null,
                    React.createElement(Inner, null,
                        React.createElement(LeftBlock, null,
                            React.createElement(List, null,
                                React.createElement(ListItem, null,
                                    React.createElement(Typography, { bold: true, size: 1 }, "id"),
                                    React.createElement(TextRightTypography, null, patientHt.patientId)),
                                React.createElement(ListItem, null,
                                    React.createElement(Typography, { bold: true, size: 1 }, "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9"),
                                    React.createElement(TextRightTypography, null, patientHt.email)),
                                React.createElement(ListItem, null,
                                    React.createElement(Typography, { bold: true, size: 1 }, "\u51E6\u65B9\u65E5"),
                                    React.createElement(TextRightTypography, null, patientHt.prescribedAt)),
                                React.createElement(ListItem, null,
                                    React.createElement(Typography, { bold: true, size: 1 }, "\u30A2\u30D7\u30EA\u540D"),
                                    React.createElement(TextRightTypography, null, patientHt.appName)))),
                        React.createElement(RightBlock, null,
                            React.createElement(List, null,
                                React.createElement(ListItem, null,
                                    React.createElement(Typography, { bold: true, size: 1 }, "\u751F\u5E74\u6708\u65E5"),
                                    React.createElement(TextRightTypography, null, patientHt.birthday)),
                                React.createElement(ListItem, null,
                                    React.createElement(Typography, { bold: true, size: 1 }, "\u51E6\u65B9\u30B3\u30FC\u30C9"),
                                    React.createElement(TextRightTypography, null, patientHt.prescriptionCode)),
                                React.createElement(ListItem, null,
                                    React.createElement(Typography, { bold: true, size: 1 }, "\u51E6\u65B9\u30B3\u30FC\u30C9ID"),
                                    React.createElement(TextRightTypography, null, patientHt.prescriptionCodeId)),
                                React.createElement(ListItem, null,
                                    React.createElement(Typography, { bold: true, size: 1 }, "\u51E6\u65B9\u533B\u7642\u6A5F\u95A2"),
                                    React.createElement(TextRightTypography, null,
                                        React.createElement(StyledLink, { to: `/medicalInstitution/${patientHt.medicalInstitutionId}` }, patientHt.medicalInstitutionName))),
                                React.createElement(ListItem, null,
                                    React.createElement(Typography, { bold: true, size: 1 }, "\u51E6\u65B9\u30B3\u30FC\u30C9\u30B9\u30C6\u30FC\u30BF\u30B9"),
                                    React.createElement(TextRightTypography, null, StatusLabelMap[(_b = (_a = patientHt.prescription) === null || _a === void 0 ? void 0 : _a.prescriptionCureAppPdtCode) === null || _b === void 0 ? void 0 : _b.statusLabelForCsSystem])))))))),
        !!snackBar && (React.createElement(Snackbar, { isOpen: snackBar.isOpen, icon: React.createElement("img", { src: snackBar.type === "ok" ? okIcon : errorIcon, width: "18", height: "18", alt: snackBar.type }), text: snackBar.message, close: snackBar.close }))));
}
