import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { PageBody } from "../PageBody";
import { Label } from "../../atoms/Label";
import { Input } from "../../atoms/Input";
import { CheckInput } from "../../atoms/CheckInput";
import { Button } from "../../atoms/Button";
import { Typography } from "../../atoms/Typography";
import { ConfirmVerifyCodeModal } from "../../../containers/organisms/modal/ConfirmVerifyCodeModal";
import { openModal, closeModal } from "../../../graphql/local-mutations";
import { defaultTheme } from "../../themes";
import { Snackbar } from "../../atoms/Snackbar";
import okIcon from "../../../assets/icons/ok.png";
import errorIcon from "../../../assets/icons/alert.png";
const ContentWrapper = styled.div `
  padding: 20px 40px;
  display: flex;
  flex-wrap: wrap;
`;
const ItemWrapper = styled.div `
  ${(props) => props.marginBottom && `margin-bottom: ${props.marginBottom}`};
  ${(props) => props.width && `width: ${props.width}`};
  padding: 0 30px 5px 0;
`;
const ButtonWrapper = styled.div `
  ${(props) => props.marginTop && `margin-top: ${props.marginTop}`};
  width: ${(props) => props.width || "214px"};
  height: 44px;
`;
const modalName = "confirmVerifyCode";
/**
 * 確認番号の発行ページ
 */
export const PublishVerifyCodePage = ({ verifyCode, permanent, confirmVerifyCodeModalOpened, snackBar, isLoading, setVerifyCode, setPermanent, onPublishVerifyCodeClick, onCancelClick, }) => {
    const onPagePublishClick = () => openModal(modalName);
    const onModalCancelClick = () => closeModal(modalName);
    const onVerifyCodeChange = (e) => setVerifyCode(e.target.value);
    const onPermanentChange = (e) => setPermanent(e.target.checked);
    return (React.createElement(React.Fragment, null,
        React.createElement(PageBody, { title: "確認番号の発行", buttonTitle: "中止", buttonType: "danger", buttonOutline: true, onButtonClick: onCancelClick, buttonIcon: React.createElement(FontAwesomeIcon, { icon: faTimes, color: defaultTheme.colors.caution }) },
            React.createElement(ContentWrapper, null,
                React.createElement(Label, { text: "確認番号", margin: "0 0 7px 0" }),
                React.createElement(ItemWrapper, { width: "100%" },
                    React.createElement(Input, { onChange: onVerifyCodeChange, value: verifyCode })),
                React.createElement(ItemWrapper, { width: "100%" },
                    React.createElement(CheckInput, { onChange: onPermanentChange, label: "\u6C38\u7D9A\u7684\u306B\u4F7F\u7528", checked: permanent, value: "true" })),
                React.createElement(ButtonWrapper, { width: "334px" },
                    React.createElement(Button, { onClick: onPagePublishClick, disabled: !verifyCode, buttonType: "primary" },
                        React.createElement(Typography, { size: 1 }, "発行して患者にメールを送る"))))),
        React.createElement(ConfirmVerifyCodeModal, { isOpen: confirmVerifyCodeModalOpened, value: verifyCode, isLoading: isLoading, title: "確認番号を発行してもよろしいですか？", onPublishClick: onPublishVerifyCodeClick, onCancelClick: onModalCancelClick }),
        !!snackBar && !!verifyCode && (React.createElement(Snackbar, { isOpen: snackBar.isOpen, icon: React.createElement("img", { src: snackBar.type === "ok" ? okIcon : errorIcon, width: "18", height: "18", alt: snackBar.type }), text: snackBar.message, close: snackBar.close }))));
};
