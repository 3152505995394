export const sortDeliveryOrderDetail = (deliveryOrder, sort) => {
    const deliveryOrderProducts = [];
    if (deliveryOrder.deliveryHistory.length > 0) {
        for (const deliveryHistory of deliveryOrder.deliveryHistory) {
            deliveryOrderProducts.push({
                product: deliveryHistory.product,
                quantity: deliveryHistory.quantity,
                serialNumber: deliveryHistory.serialNumber,
            });
        }
    }
    else {
        for (const deliveryProduct of deliveryOrder.deliveryProducts) {
            deliveryOrderProducts.push({
                product: deliveryProduct.product,
                quantity: deliveryProduct.quantity,
                serialNumber: undefined,
            });
        }
    }
    if (sort) {
        deliveryOrderProducts.sort((a, b) => {
            if (sort.field === "quantity") {
                if (Number(a.quantity) < Number(b.quantity)) {
                    return sort.value === "ASC" ? -1 : 1;
                }
                if (Number(a.quantity) > Number(b.quantity)) {
                    return sort.value === "ASC" ? 1 : -1;
                }
            }
            if (sort.field === "productName") {
                if (a.product.name < b.product.name) {
                    return sort.value === "ASC" ? -1 : 1;
                }
                if (a.product.name > b.product.name) {
                    return sort.value === "ASC" ? 1 : -1;
                }
            }
            if (sort.field === "serialNumber") {
                if (a.serialNumber < b.serialNumber) {
                    return sort.value === "ASC" ? -1 : 1;
                }
                if (a.serialNumber > b.serialNumber) {
                    return sort.value === "ASC" ? 1 : -1;
                }
            }
            return 0;
        });
    }
    return {
        deliveryOrderProducts,
    };
};
