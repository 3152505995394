import { useState } from "react";
export const useSnackBar = () => {
    const [snackBar, setSnackBar] = useState(null);
    const showOKSnackBar = (message) => {
        setSnackBar({
            type: "ok",
            message,
            isOpen: true,
            close() {
                setSnackBar(null);
            },
        });
    };
    const showErrorSnackBar = (message) => {
        setSnackBar({
            type: "error",
            message,
            isOpen: true,
            close() {
                setSnackBar(null);
            },
        });
    };
    return {
        snackBar,
        showOKSnackBar,
        showErrorSnackBar,
    };
};
