import { useQuery } from "@apollo/client";
import { useState, useCallback } from "react";
import { PAGINATION_LIMIT } from "../settings";
import { useHandleError } from "./useHandleError";
import { GET_DELIVERY_ORDERS, GET_DELIVERY_ORDERS_WITH_KEYWORDS, } from "../graphql/queries";
export const useListDataWithLoadMore = (query, dataName, dataNotFoundMessage) => {
    const [keyword, setKeyword] = useState("");
    // NOTE:患者一覧画面においてデフォルトでは処方日の降順でソートする
    const defaultOrder = dataName === "scUsers"
        ? {
            field: "prescribedAt",
            value: "DESC" /* GQLQueryOrderValue.Desc */,
        }
        : dataName === "htUsers"
            ? {
                field: "usageRecord.createdAt",
                value: "DESC" /* GQLQueryOrderValue.Desc */,
            }
            : undefined;
    const [order, setOrder] = useState(defaultOrder);
    const [skip, setSkip] = useState(0);
    const { previousData, data, error } = useQuery(query, {
        variables: { keyword, order, take: PAGINATION_LIMIT, skip },
        onCompleted: () => {
            var _a;
            // check if there is no incoming data
            if (skip !== 0 &&
                (previousData === null || previousData === void 0 ? void 0 : previousData[dataName]) != null &&
                (previousData === null || previousData === void 0 ? void 0 : previousData[dataName].length) === ((_a = data === null || data === void 0 ? void 0 : data[dataName]) === null || _a === void 0 ? void 0 : _a.length)) {
                // eslint-disable-next-line no-alert
                alert(dataNotFoundMessage);
            }
        },
    });
    useHandleError(error);
    const onSearchClick = useCallback((input) => {
        setSkip(0);
        setKeyword(input);
    }, []);
    const onResetClick = useCallback(() => {
        setSkip(0);
        setKeyword("");
    }, []);
    const onSortClick = useCallback((input) => {
        setOrder(input);
        setSkip(0);
    }, []);
    const onLoadMoreClick = useCallback((newSkip) => {
        // when user click on 「さらに表示」button after alert has been show
        // skip state doesn't change
        // → API will not being called again
        // → onCompleted not being called
        // → alert not show
        // → we need to compare skip and show alert in this case
        if (newSkip === skip) {
            // eslint-disable-next-line no-alert
            alert(dataNotFoundMessage);
        }
        else {
            setSkip(newSkip);
        }
    }, [skip, dataNotFoundMessage]);
    return {
        data,
        variables: {
            keyword,
            order,
            skip,
        },
        handlers: {
            onSearchClick,
            onResetClick,
            onSortClick,
            onLoadMoreClick,
        },
    };
};
export const useListDataWithLoadMoreForDeliveryOrder = () => {
    const [keywords, setKeywords] = useState("");
    const [order, setOrder] = useState();
    const [offset, setOffset] = useState(0);
    const dataNotFoundMessage = "物品情報は全て表示されています";
    let variables;
    let query;
    let dataName;
    if (keywords !== "") {
        variables = { keywords, order, limit: PAGINATION_LIMIT, offset };
        query = GET_DELIVERY_ORDERS_WITH_KEYWORDS;
        dataName = "deliveryOrdersWithKeywords";
    }
    else {
        variables = { order, limit: PAGINATION_LIMIT, offset };
        query = GET_DELIVERY_ORDERS;
        dataName = "deliveryOrders";
    }
    const { previousData, data, error } = useQuery(query, {
        variables: variables,
        onCompleted: () => {
            // check if there is no incoming data
            if (offset !== 0 &&
                (previousData === null || previousData === void 0 ? void 0 : previousData[dataName].length) === (data === null || data === void 0 ? void 0 : data[dataName].length)) {
                // eslint-disable-next-line no-alert
                alert(dataNotFoundMessage);
            }
        },
    });
    useHandleError(error);
    const onSearchClick = useCallback((input) => {
        setOrder(undefined);
        setOffset(0);
        setKeywords(input);
    }, []);
    const onResetClick = useCallback(() => {
        setOffset(0);
        setKeywords("");
    }, []);
    const onSortClick = useCallback((input) => {
        setOrder(input);
        setOffset(0);
    }, []);
    const onLoadMoreClick = useCallback((newOffset) => {
        // when user click on 「さらに表示」button after alert has been show
        // skip state doesn't change
        // → API will not being called again
        // → onCompleted not being called
        // → alert not show
        // → we need to compare skip and show alert in this case
        if (newOffset === offset) {
            // eslint-disable-next-line no-alert
            alert(dataNotFoundMessage);
        }
        else {
            setOffset(newOffset);
        }
    }, [offset, dataNotFoundMessage]);
    return {
        data,
        variables: {
            keywords,
            order,
            offset,
        },
        handlers: {
            onSearchClick,
            onResetClick,
            onSortClick,
            onLoadMoreClick,
        },
    };
};
