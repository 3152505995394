import React from "react";
import styled from "styled-components";
import { ErrorMessage } from "./ErrorMessage";
import { Typography } from "./Typography";
const StyledLabel = styled.label `
  text-align: left;
`;
const StyledTypography = styled(Typography) `
  font-size: ${(props) => props.theme.fontSize.small};
  padding-left: 5px;
  display: inline;
`;
CheckInput.defaultProps = {
    value: true,
    checked: false,
    errorMessages: [],
    disabled: false,
};
export function CheckInput({ label, value, checked, onChange, errorMessages, dataId, disabled, }) {
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledLabel, null,
            React.createElement("input", { type: "checkbox", checked: checked, value: value, onChange: onChange, "data-id": dataId, disabled: disabled }),
            React.createElement(StyledTypography, null, label)),
        React.createElement(ErrorMessage, { errorMessage: errorMessages && errorMessages.find((message) => message !== "") })));
}
