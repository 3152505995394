var _a, _b;
export const GRAPHQL_URL = `${process.env.BACKEND_URL}/graphql`;
export const BACKEND_URL = process.env.BACKEND_URL;
export const PAGINATION_LIMIT = 20;
export const AWS_AUTH_CONFIG = {
    Auth: {
        region: process.env.AWS_REGION,
        userPoolId: process.env.AWS_USER_POOL_ID,
        userPoolWebClientId: process.env.AWS_USER_POOL_WEB_CLIENT_ID,
        oauth: {
            domain: process.env.AWS_USER_POOL_CUSTOM_DOMAIN,
            scope: ["openid", "aws.cognito.signin.user.admin"],
            redirectSignIn: process.env.AWS_USER_POOL_CALLBACK_URL,
            redirectSignOut: process.env.AWS_USER_POOL_CALLBACK_URL,
            responseType: "code",
        },
    },
};
export const AWS_AUTH_IDP = (_a = process.env.AWS_USER_POOL_IDENTITY_PROVIDER_NAME) !== null && _a !== void 0 ? _a : "AzureAD";
export const DEBUG = process.env.DEBUG
    ? process.env.DEBUG
    : "*";
export const VERSION = (_b = process.env.VERSION) !== null && _b !== void 0 ? _b : "";
export const SENTRY_DSN = process.env.SENTRY_DSN_FRONTEND;
