import React from "react";
import styled from "styled-components";
import { Button } from "./Button";
import { Typography } from "./Typography";
const Container = styled.div `
  width: 100%;
  height: 50px;
  max-width: 250px;
  margin: 10px auto 0;
`;
export function LoadMoreButton({ onClick }) {
    return (React.createElement(Container, null,
        React.createElement(Button, { onClick: onClick, buttonType: "primary" },
            React.createElement(Typography, { size: 2 }, "\u3055\u3089\u306B\u8868\u793A"))));
}
