export const createUserMutations = (userVar) => {
    const login = (userAttibutes) => {
        userVar({
            ...userVar(),
            isLoggedIn: true,
            name: userAttibutes.name,
            userRole: userAttibutes["custom:user_role"],
        });
    };
    const logout = () => {
        userVar({ ...userVar(), isLoggedIn: false });
    };
    const expiresSession = () => {
        userVar({ ...userVar(), sessionExpired: true });
    };
    return {
        login,
        logout,
        expiresSession,
    };
};
