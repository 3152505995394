import React from "react";
import { useQuery } from "@apollo/client";
import { GET_APP_STATE } from "../../graphql/queries";
import { hideSnackBar } from "../../graphql/local-mutations";
import { Snackbar as Component, } from "../../components/atoms/Snackbar";
import okIcon from "../../assets/icons/ok.png";
/**
 * SNACKBAR CONTAINER
 */
export const Snackbar = () => {
    const { data: appData } = useQuery(GET_APP_STATE);
    const { isOpen, text } = appData
        ? appData.appState.snackbar
        : { isOpen: false, text: null };
    const props = {
        isOpen,
        close: () => hideSnackBar(),
        icon: React.createElement("img", { src: okIcon, width: "18", height: "18", alt: "ok" }),
        text,
    };
    return React.createElement(Component, { ...props });
};
