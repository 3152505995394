import React, { useCallback } from "react";
import styled from "styled-components";
import { PageBody } from "../PageBody";
import { DataListBody, DataListBodyItem, DataListHeader, DataListHeaderItem, DataListRow, DataTable, SwitchHeaderOrder, } from "../../atoms/Table";
import { Typography } from "../../atoms/Typography";
import { LoadMoreButton } from "../../atoms/LoadMoreButton";
const Container = styled.div `
  display: flex;
  flex-direction: column;
  padding: 30px 40px;
  height: 100%;
`;
const CustomerNameHeaderWrapper = styled.div `
  margin-left: 20px;
`;
const ItemNameHeaderWrapper = styled.div `
  margin-left: 40px;
`;
const DeliveryDateHeaderWrapper = styled.div `
  margin-left: 30px;
`;
const DeliveryDateWrapper = styled.div `
  position: relative;
`;
export function DeliveryOrderPage({ deliveryOrders, order, onSortClick, onRowClick, onLoadMoreClick, }) {
    const sortClick = useCallback((value) => {
        onSortClick(SwitchHeaderOrder(value, order));
    }, [onSortClick, order]);
    const header = (React.createElement(React.Fragment, null,
        React.createElement(DataListHeaderItem, { onSort: () => sortClick("name"), order: (order === null || order === void 0 ? void 0 : order.field) === "name" ? order.value : undefined },
            React.createElement(CustomerNameHeaderWrapper, null,
                React.createElement(Typography, { size: 1, bold: true }, "\u6C0F\u540D"))),
        React.createElement(DataListHeaderItem, null,
            React.createElement(ItemNameHeaderWrapper, null,
                React.createElement(Typography, { size: 1, bold: true }, "\u7269\u54C1\u540D"))),
        React.createElement(DataListHeaderItem, null,
            React.createElement(Typography, { size: 1, bold: true }, "\u500B\u6570")),
        React.createElement(DataListHeaderItem, null,
            React.createElement(Typography, { size: 1, bold: true }, "\u30B7\u30EA\u30A2\u30EBNo")),
        React.createElement(DataListHeaderItem, { onSort: () => sortClick("deliveryDate"), order: (order === null || order === void 0 ? void 0 : order.field) === "deliveryDate" ? order.value : undefined },
            React.createElement(DeliveryDateHeaderWrapper, null,
                React.createElement(Typography, { size: 1, bold: true }, "\u914D\u9001\u65E5")))));
    return (React.createElement(PageBody, { title: "\u914D\u9001\u4F9D\u983C\u4E00\u89A7" },
        React.createElement(Container, null,
            React.createElement(DataTable, null,
                React.createElement(DataListHeader, { row: header, flexRatio: [3, 4, 2, 2, 2] }),
                React.createElement(DataListBody, { rows: deliveryOrders.map((deliveryOrder) => {
                        var _a, _b, _c, _d;
                        return (React.createElement(DataListRow, { flexRatio: [3, 4, 2, 2, 2], key: deliveryOrder.id, onRowClick: () => onRowClick(deliveryOrder.id) },
                            React.createElement(DataListBodyItem, null,
                                React.createElement(Typography, { size: 2, withEllipsis: true }, deliveryOrder.name)),
                            React.createElement(DataListBodyItem, null,
                                React.createElement(Typography, { size: 2, withEllipsis: true }, (deliveryOrder.deliveryHistory &&
                                    ((_b = (_a = deliveryOrder.deliveryHistory[0]) === null || _a === void 0 ? void 0 : _a.product) === null || _b === void 0 ? void 0 : _b.name)) ||
                                    deliveryOrder.deliveryProducts[0].product.name)),
                            React.createElement(DataListBodyItem, null,
                                React.createElement(Typography, { size: 2, withEllipsis: true }, (deliveryOrder.deliveryHistory &&
                                    ((_c = deliveryOrder.deliveryProducts[0]) === null || _c === void 0 ? void 0 : _c.quantity)) ||
                                    "-")),
                            React.createElement(DataListBodyItem, null,
                                React.createElement(Typography, { size: 2, withEllipsis: true }, (deliveryOrder.deliveryHistory &&
                                    ((_d = deliveryOrder.deliveryHistory[0]) === null || _d === void 0 ? void 0 : _d.serialNumber)) ||
                                    "-")),
                            React.createElement(DataListBodyItem, null,
                                React.createElement(DeliveryDateWrapper, null,
                                    React.createElement(Typography, { size: 2, withEllipsis: true }, deliveryOrder.deliveryDate || "-")))));
                    }) })),
            React.createElement(LoadMoreButton, { onClick: () => onLoadMoreClick(deliveryOrders.length) }))));
}
