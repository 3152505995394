import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
export const GlobalStyle = createGlobalStyle `
  ${reset}
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  button {
    padding: 0;
  }
  body {
    position: relative;
    font-family: ${({ theme }) => theme.fonts.sanSerif};
    color: ${({ theme }) => theme.colors.primaryText};
    min-width: 900px;
  }

  // For Print
  @page {
    size: A4;
    margin: 0;
  }
  @media print {
    html, body {
      width: 210mm;
      height: 296mm;
    }
  }
`;
