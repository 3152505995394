import React from "react";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";
import { Button } from "../atoms/Button";
import { hexToRGB } from "../../utils/style";
import { Typography } from "../atoms/Typography";
const StyledCSSTransition = styled(CSSTransition) `
  &.announce-enter {
    opacity: 0;
  }
  &.announce-enter-active {
    opacity: 1;
    transition: all 300ms ease-in-out;
  }
  &.announce-exit {
    opacity: 1;
  }
  &.announce-exit-active {
    opacity: 0;
    transition: all 300ms ease-in-out;
  }
`;
const Container = styled.div `
  width: 240px;
  height: 339px;
  position: absolute;
  top: 38px;
  right: 0;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid ${(props) => props.theme.colors.primaryBorder};
  background-color: ${(props) => props.theme.colors.primaryBackground};
  box-shadow: 8px 8px 8px
    ${(props) => hexToRGB(props.theme.colors.secondaryBackground, 0.06)};
  display: flex;
  flex-direction: column;
  z-index: 1;
`;
const UserBlock = styled.div `
  flex: 3;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${(props) => props.theme.colors.primaryBorder};
`;
const UserArea = styled.div `
  flex: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    margin-bottom: 17px;
  }
`;
const NameWrapper = styled.div `
  margin-bottom: 8px;
`;
const AuthorityWrapper = styled.div `
  opacity: 0.8;
`;
const LogoutBlock = styled.div `
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LogoutButtonWrapper = styled.div `
  width: 180px;
  height: 44px;
`;
export const HamburgerMenu = ({ isOpen, user, toggleMenu, onLogoutClick, }) => {
    const logoutClick = () => {
        toggleMenu();
        onLogoutClick();
    };
    return (React.createElement(StyledCSSTransition, { in: isOpen, timeout: 300, classNames: "announce", unmountOnExit: true },
        React.createElement(Container, null,
            React.createElement(UserBlock, null,
                React.createElement(UserArea, null,
                    user.image && (React.createElement("img", { src: user.image, width: "72", height: "72", alt: "user_icon" })),
                    React.createElement(NameWrapper, null,
                        React.createElement(Typography, { size: 2, bold: true }, user.name)),
                    React.createElement(AuthorityWrapper, null,
                        React.createElement(Typography, { size: 1 }, user.authority)))),
            React.createElement(LogoutBlock, null,
                React.createElement(LogoutButtonWrapper, null,
                    React.createElement(Button, { buttonType: "danger", ghost: true, onClick: logoutClick },
                        React.createElement(Typography, { size: 2 }, "\u30ED\u30B0\u30A2\u30A6\u30C8")))))));
};
HamburgerMenu.defaultProps = {
    isOpen: false,
};
