import { gql } from "@apollo/client";
/**
 * local state
 */
export const GET_APP_STATE = gql `
  query {
    appState @client
  }
`;
export const GET_USER_STATE = gql `
  query {
    userState @client
  }
`;
export const GET_MODAL_STATE = gql `
  query {
    modalState @client
  }
`;
/**
 * Delivery Order
 */
export const DeliveryOrderFragments = {
    details: gql `
    fragment DeliveryOrderDetails on DeliveryOrder {
      id
      name
      postalCode
      address
      phoneNumber
      deliveryTime {
        code
        section
      }
      deliveryStatus {
        code
      }
      deliveryProducts {
        product {
          code
          name
          description
          unitSize
        }
        quantity
      }
    }
  `,
};
export const GET_DELIVERY_ORDERS_WITH_KEYWORDS = gql `
  query getDeliveryOrdersWithKeywords(
    $offset: Int
    $limit: Int
    $order: QueryOrderInput
    $keywords: [String!]!
  ) {
    deliveryOrdersWithKeywords(
      offset: $offset
      limit: $limit
      order: $order
      keywords: $keywords
    ) {
      id
      name
      phoneNumber
      postalCode
      address
      deliveryDate
      deliveryTime {
        code
        section
      }
      deliveryStatus {
        code
      }
      deliveryHistory {
        serialNumber
        slipNumber
        product {
          code
          name
        }
      }
      deliveryProducts {
        product {
          code
          name
          description
          unitSize
        }
        quantity
      }
    }
  }
`;
export const GET_DELIVERY_ORDERS = gql `
  query getDeliveryOrder($offset: Int, $limit: Int, $order: QueryOrderInput) {
    deliveryOrders(offset: $offset, limit: $limit, order: $order) {
      id
      name
      phoneNumber
      postalCode
      address
      deliveryDate
      deliveryTime {
        code
        section
      }
      deliveryStatus {
        code
      }
      deliveryHistory {
        serialNumber
        slipNumber
        product {
          code
          name
        }
      }
      deliveryProducts {
        product {
          code
          name
          description
          unitSize
        }
        quantity
      }
    }
  }
`;
export const GET_DELIVERY_ORDER = gql `
  query getDeliveryOrder($id: ID!) {
    deliveryOrder(id: $id) {
      id
      name
      phoneNumber
      postalCode
      address
      deliveryDate
      deliveryTime {
        code
        section
      }
      orderClass {
        name
      }
      deliveryStatus {
        code
      }
      deliveryHistory {
        serialNumber
        slipNumber
        product {
          code
          name
        }
      }
      deliveryProducts {
        product {
          code
          name
          description
          unitSize
        }
        quantity
      }
    }
  }
`;
/**
 * Medical Institution
 */
export const GET_MEDICAL_INSTITUTIONS = gql `
  query GetMedicalInstitutions(
    $skip: Int
    $take: Int
    $order: QueryOrderInput
    $keyword: String
  ) {
    medicalInstitutions(
      skip: $skip
      take: $take
      order: $order
      keyword: $keyword
    ) {
      id
      name
      postalCode
      prefecture
      city
      otherAddress
      phoneNumber
      mailAddress
      representativeName
    }
  }
`;
export const GET_MEDICAL_INSTITUTION = gql `
  query GetMedicalInstitutionDetail($medicalInstitutionId: ID!) {
    medicalInstitutionDetail(medicalInstitutionId: $medicalInstitutionId) {
      id
      name
      phoneNumber
      postalCode
      prefecture
      city
      otherAddress
      mailAddress
      representativeName
      isSetupFinished
      clientCertificate {
        id
        name
        domain
        expiresAt
        downloadUrl
        isUsed
      }
    }
  }
`;
export const GET_CLIENT_CERTIFICATE = gql `
  query GetClientCertificate($domain: String!) {
    clientCertificate(domain: $domain) {
      id
      password
    }
  }
`;
export const GET_SC_USERS = gql `
  query getScUsers(
    $skip: Int
    $take: Int
    $order: QueryOrderInput
    $keyword: String
  ) {
    scUsers(skip: $skip, take: $take, order: $order, keyword: $keyword) {
      id
      birthday
      firstName
      lastName
      appName
      prescribedAt
      prescriptionCode
      prescriptionCodeId
      registeredAt
    }
  }
`;
export const GET_SC_USER = gql `
  query getScUser($id: ID!) {
    scUser(id: $id) {
      id
      birthday
      email
      coCheckerSerial
      phoneNumber
      firstName
      lastName
      appName
      prescribedAt
      prescriptionCode
      prescriptionCodeId
      registeredAt
      medicalInstitutionId
      medicalInstitutionName
      prescription {
        id
        prescriptionCureAppPdtCode {
          status
          statusLabelForCsSystem
        }
      }
    }
  }
`;
export const GET_HT_USERS = gql `
  query getUsers(
    $skip: Int
    $take: Int
    $keyword: String
    $order: QueryOrderInput
  ) {
    htUsers(skip: $skip, take: $take, keyword: $keyword, order: $order) {
      id
      birthday
      nickname
      appName
      prescribedAt
      prescriptionCode
      prescriptionCodeId
    }
  }
`;
export const GET_HT_USER = gql `
  query getUser($id: ID!) {
    htUser(id: $id) {
      id
      prescriptionCode
      prescriptionCodeId
      prescribedAt
      status
      nickname
      appName
      pdtCode
      pdtId
      birthday
      medicalInstitutionId
      medicalInstitutionName
      email
      createdAt
      bloodPressureMeasurementRate
      maskedDetail
      prescription {
        id
        prescriptionCureAppPdtCode {
          status
          statusLabelForCsSystem
        }
      }
    }
  }
`;
export const GET_MEDICAL_INSTITUTION_MEMBERS_BY_MEDICAL_INSTITUITON_ID = gql `
  query medicalInstitutionMembersByMedicalInstitutionId(
    $medicalInstitutionId: ID!
  ) {
    medicalInstitutionMembersByMedicalInstitutionId(
      medicalInstitutionId: $medicalInstitutionId
    ) {
      id
      name
      status
      position
      role
      createdAt
    }
  }
`;
/**
 * products
 */
export const GET_PRODUCTS = gql `
  query getProducts {
    products {
      code
      name
      description
    }
  }
`;
/**
 * pdts
 */
export const GET_PDTS = gql `
  query getPdts {
    pdts {
      id
      name
      siteUrl
      officialUrl
      supportUrl
      category
      enableFlag
      hasTentativePrescriptions
      iconPath
      description
      vendor {
        name
      }
      pdtDocuments {
        id
        name
      }
    }
  }
`;
/**
 * pdts
 */
export const GET_PDT = gql `
  query getPdt($id: ID!) {
    pdt(id: $id) {
      id
      name
      siteUrl
      officialUrl
      supportUrl
      category
      enableFlag
      hasTentativePrescriptions
      iconPath
      description
      createdAt
      vendor {
        name
      }
      pdtDocuments {
        id
        name
        url
        version
        enableFlag
        createdAt
      }
    }
  }
`;
/**
 * Prescription
 */
export const GET_PRESCRIPTION_BY_PRESCRIPTION_CODE = gql `
  query getPrescriptionByPrescriptionCode($prescriptionCode: String!) {
    prescriptionByPrescriptionCode(prescriptionCode: $prescriptionCode) {
      id
      name
      birthday
      createdAt
      appName
      medicalInstitutionName
      codeId
      code
      codeStatus
    }
  }
`;
