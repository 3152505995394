import React from "react";
import ReactDOM from "react-dom";
import { init } from "@sentry/react";
import { SENTRY_DSN, VERSION } from "./settings";
import { App } from "./App";
if (SENTRY_DSN) {
    init({
        dsn: SENTRY_DSN,
        release: VERSION,
    });
}
ReactDOM.render(React.createElement(App, null), document.getElementById("react-root"));
