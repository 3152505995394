import React from "react";
import styled from "styled-components";
import { Typography } from "../atoms/Typography";
import { DataTable, DataListSimpleHeader, DataListBody, DataListRow, DataListBodyItem, } from "../atoms/Table";
import { Snackbar } from "../atoms/Snackbar";
import { Button } from "../atoms/Button";
import { ConfirmModal } from "../../containers/organisms/modal/ConfirmModal";
import okIcon from "../../assets/icons/ok.png";
import errorIcon from "../../assets/icons/alert.png";
import { closeModal } from "../../graphql/local-mutations";
const ClientCertificatePasswordButtonWrapper = styled.div `
  width: 200px;
  height: 30px;
  margin-left: auto;
`;
export function ClientCertificate({ clientCertificate, handleGetClientCertificatePassword, handleCopyPasswordToClipboard, isOpen, password, snackBar, }) {
    const flexRatio = [3, 1];
    const headerTitles = ["クライアント証明書名"];
    const item = [
        React.createElement(DataListRow, { flexRatio: flexRatio },
            React.createElement(DataListBodyItem, null,
                React.createElement(Typography, { size: 2, withEllipsis: true }, clientCertificate.name)),
            React.createElement(DataListBodyItem, null,
                React.createElement(ClientCertificatePasswordButtonWrapper, null,
                    React.createElement(Button, { buttonType: "primary", onClick: handleGetClientCertificatePassword }, "\u30D1\u30B9\u30EF\u30FC\u30C9\u3092\u78BA\u8A8D\u3059\u308B")))),
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(DataTable, null,
            React.createElement(DataListSimpleHeader, { titles: headerTitles, flexRatio: flexRatio }),
            item && React.createElement(DataListBody, { rows: item })),
        React.createElement(ConfirmModal, { isOpen: isOpen, value: password, isLoading: false, title: `${clientCertificate.name}のパスワードをクリップボードにコピーしますか？`, onChangeClick: () => handleCopyPasswordToClipboard(password), closeModal: () => closeModal("confirmClientCertificatePassword") }),
        !!snackBar && (React.createElement(Snackbar, { isOpen: snackBar.isOpen, icon: React.createElement("img", { src: snackBar.type === "ok" ? okIcon : errorIcon, width: "18", height: "18", alt: snackBar.type }), text: snackBar.message, close: snackBar.close }))));
}
