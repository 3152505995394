import React, { useMemo } from "react";
import { ReissueSecretCodeMutation } from "../../graphql/mutations";
import { Button } from "../atoms/Button";
const hasDisableStatus = (status) => status === "停止中";
export const ReissueSecretCodeButton = ({ medicalInstitutionMemberId, medicalInstitutionMemberName, medicalInstitutionMemberStatus, refetchMedicalInstitutionMembers, }) => {
    const [reissueSecretCodeMutation] = ReissueSecretCodeMutation();
    const isDisable = useMemo(() => hasDisableStatus(medicalInstitutionMemberStatus), [medicalInstitutionMemberStatus]);
    const onClick = async () => {
        let secretCode;
        // eslint-disable-next-line no-alert
        const enableReissue = confirm(`「${medicalInstitutionMemberName}」さんのシークレットコードを再発行しますか？`);
        if (!enableReissue)
            return;
        try {
            const { data: reissueSecretCodePayload, errors, } = await reissueSecretCodeMutation({
                variables: { medicalInstitutionMemberId },
            });
            if (!(reissueSecretCodePayload === null || reissueSecretCodePayload === void 0 ? void 0 : reissueSecretCodePayload.reissueSecretCode))
                throw errors;
            secretCode = reissueSecretCodePayload.reissueSecretCode.secretCode;
            if (!secretCode)
                throw errors;
        }
        catch (_a) {
            // eslint-disable-next-line no-alert
            alert("シークレットコードの再発行に失敗しました");
            return;
        }
        const failedClipboardMessage = `「${medicalInstitutionMemberName}」さんのシークレットコードを再発行しました。\n閉じる前に必ずメモして下さい。\n\nシークレットコード: ${secretCode}`;
        // NOTE: confirmダイアログが表示されるとdocumentから一旦フォーカスが外れコピーできないため、documentにフォーカスが戻るのを待って処理する
        const writeTextInterval = setInterval(() => {
            if (document.hasFocus()) {
                clearInterval(writeTextInterval);
                // NOTE: documentにフォーカスがあたってからでないとチェックできない
                if (navigator.clipboard) {
                    navigator.clipboard.writeText(secretCode).then(() => {
                        // eslint-disable-next-line no-alert
                        alert(`「${medicalInstitutionMemberName}」さんのシークレットコードを再発行しました。\nシークレットコードはクリップボードへコピーしています。\n\nシークレットコード: ${secretCode}`);
                    }, () => {
                        // eslint-disable-next-line no-alert
                        alert(failedClipboardMessage);
                    });
                }
                else {
                    // eslint-disable-next-line no-alert
                    alert(failedClipboardMessage);
                }
            }
        }, 300);
        refetchMedicalInstitutionMembers();
    };
    return (React.createElement(Button, { buttonType: "primary", onClick: onClick, disabled: isDisable }, "\u30B7\u30FC\u30AF\u30EC\u30C3\u30C8\u30B3\u30FC\u30C9\u306E\u518D\u767A\u884C"));
};
