import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import logout from "../../../assets/images/logout.png";
import { Typography } from "../../atoms/Typography";
/**
 * ログアウト完了ページ
 */
const Container = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    margin-top: 80px;
  }
`;
const TitleWrapper = styled.div `
  margin-bottom: 50px;
`;
const StyledLink = styled(Link) `
  cursor: pointer;
  width: 100%;
  max-width: 400px;
  height: 44px;
  background-color: ${(props) => props.theme.colors.brand};
  text-decoration: none;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.secondaryText};
`;
export function LogoutCompleted() {
    return (React.createElement(Container, null,
        React.createElement("img", { src: logout, width: "300", height: "300", alt: "logout" }),
        React.createElement(TitleWrapper, null,
            React.createElement(Typography, { size: 6, bold: true }, "\u6B63\u5E38\u306B\u30ED\u30B0\u30A2\u30A6\u30C8\u3055\u308C\u307E\u3057\u305F")),
        React.createElement(StyledLink, { to: "/login" },
            React.createElement(Typography, { size: 2, bold: true }, "\u30ED\u30B0\u30A4\u30F3\u753B\u9762\u3078"))));
}
