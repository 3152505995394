import dayjs from "dayjs";
/**
 * graphqlのDate型の日付文字列(JSON)を返す。
 * 時間を9時に設定するのは そうしないとUTCで前日の15時になってしまうため
 */
export const toJSONDate = (year, month, date) => {
    const isValid = dayjs(`${year}-${month}-${date}`).format("YYYYMD") ===
        `${year}${month}${date}`;
    if (!isValid)
        throw new Error("Invalid date recieved");
    return dayjs(`${year}-${month}-${date}:9`, "YYYY-M-D:H").toJSON();
};
