import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useHandleError } from "../../../hooks";
import { GET_HT_USER } from "../../../graphql/queries";
import { parsePatientHt } from "../../../graphql/utils";
import { PatientHtDetailPage as Component, } from "../../../components/pages/patientHt/PatientHtDetailPage";
import { FORCE_CANCEL_PRESCRIPTION } from "../../../graphql/mutations";
import { useSnackBar } from "../../../hooks/useSnackBar";
import { useCheckUserRole } from "../../../hooks/useCheckUserRole";
export const PatientHtDetailPage = ({}) => {
    const history = useHistory();
    useCheckUserRole();
    const { snackBar, showOKSnackBar } = useSnackBar();
    const { id: patientId } = useParams();
    if (!patientId)
        throw new Error("patientId is undefined");
    const { error, data } = useQuery(GET_HT_USER, {
        variables: { id: patientId },
    });
    const [forceCancelPrescription] = useMutation(FORCE_CANCEL_PRESCRIPTION, {
        onCompleted: (result) => {
            var _a;
            if (((_a = result.forceCancelPrescription) === null || _a === void 0 ? void 0 : _a.__typename) ===
                "ForceCancelPrescriptionError") {
                history.push({
                    pathname: `/cancelPrescription/error`,
                    state: {
                        message: `ForceCancelPrescriptionError: ${result.forceCancelPrescription.message}`,
                        prescriptionCode: result.forceCancelPrescription.prescriptionCode,
                    },
                });
            }
            else {
                showOKSnackBar("処方をキャンセルしました。");
            }
        },
        onError: (e) => {
            history.push({
                pathname: `/cancelPrescription/error`,
                state: {
                    message: `onError: ${e.message}`,
                    prescriptionCode: data === null || data === void 0 ? void 0 : data.htUser.prescriptionCode,
                },
            });
        },
    });
    useHandleError(error);
    if (!(data === null || data === void 0 ? void 0 : data.htUser)) {
        return null;
    }
    const htUser = data.htUser;
    const handleUpdateEmailAddressButtonClick = async () => {
        history.push({
            pathname: `/patient_ht/${patientId}/updateEmailAddress`,
            state: {
                id: patientId,
                email: htUser.email,
            },
        });
    };
    const handlePublishVerifyCodeButtonClick = () => {
        history.push({
            pathname: `/patient_ht/${patientId}/publish-verify-code`,
            state: {
                id: patientId,
                email: htUser.email,
            },
        });
    };
    const handleCancelPrescriptionCodeButtonClick = async () => {
        const isOk = confirm("本当に処方をキャンセルしますか？キャンセルしたら元には戻せません。");
        if (isOk) {
            await forceCancelPrescription({
                variables: {
                    input: {
                        prescriptionCode: data === null || data === void 0 ? void 0 : data.htUser.prescriptionCode,
                    },
                },
            });
        }
    };
    const props = {
        patientHt: parsePatientHt(htUser),
        onUpdateEmailAddressButtonClick: handleUpdateEmailAddressButtonClick,
        onPublishVerifyCodeButtonClick: handlePublishVerifyCodeButtonClick,
        onCancelPrescriptionCodeButtonClick: handleCancelPrescriptionCodeButtonClick,
        maskedDetail: data === null || data === void 0 ? void 0 : data.htUser.maskedDetail,
        snackBar,
    };
    return React.createElement(Component, { ...props });
};
