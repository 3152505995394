import React, { useCallback } from "react";
import styled from "styled-components";
import { DataListBody, DataListBodyItem, DataListHeader, DataListHeaderItem, DataListRow, DataTable, SwitchHeaderOrder, } from "./Table";
import { Typography } from "./Typography";
import { hexToRGB } from "../../utils/style";
const ContentWrapper = styled.div `
  padding: 0 40px;
`;
const Inner = styled.div `
  padding: 40px 0 20px;
  display: flex;
  ${(props) => hexToRGB(props.theme.colors.primaryBorder, 0.5)};
`;
const PaddingRightWrapper = styled.div `
  width: 100%;
  padding-right: 20px;
`;
const TextRightTypography = (props) => (React.createElement(PaddingRightWrapper, null,
    React.createElement(Typography, { textRight: true, size: 2 }, props.children)));
export function DeliveryOrderDetailProduct({ products, order, onSortDetailClick, }) {
    const sortClick = useCallback((value) => {
        onSortDetailClick(SwitchHeaderOrder(value, order));
    }, [onSortDetailClick, order]);
    const header = (React.createElement(React.Fragment, null,
        React.createElement(DataListHeaderItem, { onSort: () => sortClick("productName"), order: (order === null || order === void 0 ? void 0 : order.field) === "productName" ? order.value : undefined },
            React.createElement(Typography, { size: 1, bold: true }, "\u7269\u54C1\u540D")),
        React.createElement(DataListHeaderItem, { onSort: () => sortClick("serialNumber"), order: (order === null || order === void 0 ? void 0 : order.field) === "serialNumber" ? order.value : undefined },
            React.createElement(Typography, { size: 1, bold: true }, "\u30B7\u30EA\u30A2\u30EBNo")),
        React.createElement(DataListHeaderItem, { onSort: () => sortClick("quantity"), order: (order === null || order === void 0 ? void 0 : order.field) === "quantity" ? order.value : undefined, align: "flex-end" },
            React.createElement(Typography, { size: 1, bold: true }, "\u500B\u6570"))));
    return (React.createElement(ContentWrapper, null,
        React.createElement(Inner, null,
            React.createElement(DataTable, null,
                React.createElement(DataListHeader, { row: header, flexRatio: [4, 4, 3] }),
                React.createElement(DataListBody, { rows: products.map((deliveryOrderProduct) => (React.createElement(DataListRow, { flexRatio: [4, 4, 3] },
                        React.createElement(DataListBodyItem, null,
                            React.createElement(Typography, { size: 2, withEllipsis: true }, deliveryOrderProduct.product.name)),
                        React.createElement(DataListBodyItem, null,
                            React.createElement(Typography, { size: 2, withEllipsis: true }, deliveryOrderProduct.serialNumber || "-")),
                        React.createElement(DataListBodyItem, null,
                            React.createElement(TextRightTypography, null, deliveryOrderProduct.quantity))))) })))));
}
