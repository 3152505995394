import React from "react";
import styled from "styled-components";
import { Typography } from "./Typography";
const Container = styled.div `
  display: flex;
`;
const Bar = styled.div `
  background-color: ${(props) => props.theme.colors.brand};
  width: 6px;
  border-radius: 6px;
  margin-right: 8px;
`;
export function Title(props) {
    const { text } = props;
    return (React.createElement(Container, null,
        React.createElement(Bar, null),
        React.createElement(Typography, { size: 3, bold: true }, text)));
}
