import React from "react";
import { useHistory } from "react-router-dom";
import { useListDataWithLoadMore } from "../../../hooks";
import { GET_MEDICAL_INSTITUTIONS } from "../../../graphql/queries";
import { MedicalInstitutionPage as Component, } from "../../../components/pages/medicalInstitution/MedicalInstitutionPage";
export const MedicalInstitutionPage = () => {
    const history = useHistory();
    const { data, variables: { keyword, order }, handlers: { onSearchClick, onResetClick, onSortClick, onLoadMoreClick }, } = useListDataWithLoadMore(GET_MEDICAL_INSTITUTIONS, "medicalInstitutions", "医療機関情報は全て表示されています");
    if (!(data === null || data === void 0 ? void 0 : data.medicalInstitutions)) {
        return null;
    }
    const props = {
        medicalInstitutions: data.medicalInstitutions,
        searchValue: keyword,
        order,
        onLoadMoreClick,
        onSearchClick,
        onResetClick,
        onSortClick,
        onRowClick: (medicalInstitutionId) => history.push(`medicalInstitution/${medicalInstitutionId}`),
    };
    return React.createElement(Component, { ...props });
};
