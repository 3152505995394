import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useHandleError } from "../../../hooks";
import { DeliveryOrderDetailPage as Component, } from "../../../components/pages/deliveryOrder/DeliveryOrderDetailPage";
import { DeliveryOrderDetailProduct } from "../../../components/atoms/DeliveryOrderDetailProduct";
import { GET_DELIVERY_ORDER } from "../../../graphql/queries";
import { parseDeliveryOrderDetail } from "../../../graphql/utils";
import { sortDeliveryOrderDetail } from "../../../hooks/useSortDeliveryOrderDetail";
export const DeliveryOrderDetailPage = () => {
    // @ts-ignore idが存在するのに「ない」と怒られるので
    const { id: deliveryOrderId } = useParams();
    const [sort, setSort] = useState();
    const { error, data } = useQuery(GET_DELIVERY_ORDER, {
        variables: { id: deliveryOrderId },
    });
    useHandleError(error);
    if (!deliveryOrderId)
        throw new Error("deliveryOrderId is undefined");
    useHandleError(error);
    if (!(data === null || data === void 0 ? void 0 : data.deliveryOrder)) {
        return null;
    }
    // get and sort 物品 list
    const { deliveryOrderProducts } = sortDeliveryOrderDetail(parseDeliveryOrderDetail(data.deliveryOrder), sort);
    const onSortDetailCkick = (input) => {
        setSort(input);
    };
    const productList = (React.createElement(DeliveryOrderDetailProduct, { products: deliveryOrderProducts, order: sort, onSortDetailClick: onSortDetailCkick }));
    const props = {
        deliveryOrder: parseDeliveryOrderDetail(data.deliveryOrder),
        products: productList,
    };
    return React.createElement(Component, { ...props });
};
