import React from "react";
import styled from "styled-components";
import { Typography } from "./Typography";
const Container = styled.div `
  display: flex;
  ${({ margin }) => margin && `margin: ${margin}`}
`;
const RequiredWrapper = styled.div `
  color: ${({ theme }) => theme.colors.caution};
`;
const OptionalWrapper = styled.div `
  color: ${({ theme }) => theme.colors.good};
`;
const NoteWrapper = styled.div `
  color: ${({ theme }) => theme.colors.good};
  margin-left: 5px;
`;
export function Label({ text, emphasis, note, margin }) {
    return (React.createElement(Container, { margin: margin },
        React.createElement(Typography, { size: 1, bold: true }, text),
        emphasis === "required" && (React.createElement(RequiredWrapper, null,
            React.createElement(Typography, { size: 1 }, "\uFF08\u5FC5\u9808\uFF09"))),
        emphasis === "optional" && (React.createElement(OptionalWrapper, null,
            React.createElement(Typography, { size: 1 }, "\uFF08\u4EFB\u610F\uFF09"))),
        emphasis === "note" && (React.createElement(NoteWrapper, null,
            React.createElement(Typography, { size: 1 }, note)))));
}
