import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import error from "../../assets/images/error.png";
import { Typography } from "../atoms/Typography";
import { Button } from "../atoms/Button";
/**
 * エラーページ
 */
const Container = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  img {
    margin-top: 80px;
  }
`;
const TitleWrapper = styled.div `
  margin-bottom: 18px;
`;
const SubtitleWrapper = styled.div `
  margin-bottom: 40px;
  width: 100%;
  max-width: 400px;
  height: 38px;
  text-align: center;
`;
const GoBackButtonWrapper = styled.div `
  width: 100%;
  max-width: 400px;
  height: 44px;
  background-color: ${(props) => props.theme.colors.brand};
  border-radius: 6px;
  justify-content: center;
  align-items: center;
`;
export const ErrorPage = () => {
    const history = useHistory();
    const hasPreviousPath = history.length > 2;
    return (React.createElement(Container, null,
        React.createElement("img", { src: error, width: "300", height: "300", alt: "error" }),
        React.createElement(TitleWrapper, null,
            React.createElement(Typography, { size: 6, bold: true }, "\u30A8\u30E9\u30FC\u304C\u767A\u751F\u3057\u307E\u3057\u305F")),
        React.createElement(SubtitleWrapper, null,
            React.createElement(Typography, { size: 2 }, "\u7BA1\u7406\u8005\u306B\u304A\u554F\u3044\u5408\u308F\u305B\u304F\u3060\u3055\u3044\u3002")),
        React.createElement(GoBackButtonWrapper, null,
            React.createElement(Button, { onClick: () => {
                    if (hasPreviousPath)
                        history.goBack();
                    if (history.location.pathname === "/")
                        location.reload();
                    history.push("/");
                }, buttonType: "primary" },
                React.createElement(Typography, { size: 2, bold: true }, hasPreviousPath ? "前のページにもどる" : "TOPページにもどる")))));
};
