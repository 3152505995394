import Auth from "@aws-amplify/auth";
import React from "react";
import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "styled-components";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCaretUp, faPen, faCheck, faCircle, faBell, faArrowLeft, faUserPlus, faFile, faChevronUp, faChevronDown, faChevronLeft, faChartBar, faExclamationCircle, faQuestionCircle, faSearch, } from "@fortawesome/free-solid-svg-icons";
import { useApolloClient } from "./hooks/useApolloClient";
import { defaultTheme } from "./components/themes";
import { GlobalStyle } from "./components/GlobalStyle";
import { Router } from "./Router";
import { openModal, initializeModalState, expiresSession, setNotFoundStatus, hideMenuList, setServerErrorStatus, setForbiddenStatus, setNotSameSubDomainStatus, } from "./graphql/local-mutations";
import { AWS_AUTH_CONFIG } from "./settings";
Auth.configure(AWS_AUTH_CONFIG);
const ErrorLinkHandler = ({ isSessionExpired, isNotFound, isForbidden, isServerError, isNotSameSubDomain, children, }) => {
    if (isSessionExpired) {
        initializeModalState();
        expiresSession();
        openModal("sessionExpired");
    }
    if (isNotFound) {
        setNotFoundStatus(true);
    }
    if (isForbidden) {
        hideMenuList();
        setForbiddenStatus(true);
    }
    if (isServerError) {
        initializeModalState();
        hideMenuList();
        setServerErrorStatus(true);
    }
    if (isNotSameSubDomain) {
        setNotSameSubDomainStatus(true);
    }
    return React.createElement(React.Fragment, null, children);
};
const ApolloProviderWithClient = ({ children, }) => {
    const { apolloClient, errorLinkStatus } = useApolloClient();
    const ErrorLinkHandlerProps = {
        isSessionExpired: errorLinkStatus.sessionExpired,
        isNotFound: errorLinkStatus.notFound,
        isForbidden: errorLinkStatus.forbidden,
        isServerError: errorLinkStatus.serverError,
        isNotSameSubDomain: errorLinkStatus.notSameSubDomain,
    };
    return (React.createElement(ApolloProvider, { client: apolloClient },
        React.createElement(ErrorLinkHandler, { ...ErrorLinkHandlerProps }, children)));
};
export function App() {
    /**
     * fontawesomeのIconを追加する
     */
    library.add(faCaretUp, faPen, faCheck, faCircle, faBell, faArrowLeft, faUserPlus, faFile, faChevronUp, faChevronDown, faChevronLeft, faChartBar, faExclamationCircle, faQuestionCircle, faSearch);
    return (React.createElement(ApolloProviderWithClient, null,
        React.createElement(ThemeProvider, { theme: defaultTheme },
            React.createElement(GlobalStyle, null),
            React.createElement(Router, null))));
}
