import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_USER_STATE } from "../../../graphql/queries";
import { useHandleError } from "../../../hooks";
import { GET_MEDICAL_INSTITUTION, GET_MEDICAL_INSTITUTION_MEMBERS_BY_MEDICAL_INSTITUITON_ID, } from "../../../graphql/queries";
import { parseMembers } from "../../../graphql/utils";
import { MedicalInstitutionDetailPage as Component, } from "../../../components/pages/medicalInstitution/MedicalInstitutionDetailPage";
import userIcon from "../../../assets/images/users/sample.png";
export const MedicalInstitutionDetailPage = () => {
    var _a, _b;
    const history = useHistory();
    const { data } = useQuery(GET_USER_STATE);
    const { id: medicalInstitutionId } = useParams();
    if (!medicalInstitutionId)
        throw new Error("medicalInstitutionId is undefined");
    const getMedicalInstitutionPayload = useQuery(GET_MEDICAL_INSTITUTION, {
        variables: { medicalInstitutionId },
    });
    useHandleError(getMedicalInstitutionPayload.error);
    const medicalInstitutionDetail = (_a = getMedicalInstitutionPayload.data) === null || _a === void 0 ? void 0 : _a.medicalInstitutionDetail;
    const getMedicalInstitutionMembersPayload = useQuery(GET_MEDICAL_INSTITUTION_MEMBERS_BY_MEDICAL_INSTITUITON_ID, {
        variables: { medicalInstitutionId },
    });
    useHandleError(getMedicalInstitutionMembersPayload.error);
    const medicalInstitutionMembers = (_b = getMedicalInstitutionMembersPayload.data) === null || _b === void 0 ? void 0 : _b.medicalInstitutionMembersByMedicalInstitutionId;
    if (!medicalInstitutionDetail || !medicalInstitutionMembers) {
        return null;
    }
    const handleResendWelcomeMail = async () => {
        history.push({
            pathname: `/medicalInstitution/${medicalInstitutionId}/resendWelcomeMail`,
            state: {
                mailAddress: medicalInstitutionDetail.mailAddress,
                name: medicalInstitutionDetail.name,
                representativeName: medicalInstitutionDetail.representativeName,
                id: medicalInstitutionId,
                address: `${medicalInstitutionDetail.prefecture} ${medicalInstitutionDetail.city} ${medicalInstitutionDetail.otherAddress}`,
            },
        });
    };
    const refetchMedicalInstitutionMembers = () => {
        getMedicalInstitutionMembersPayload.refetch();
    };
    const props = {
        userData: data === null || data === void 0 ? void 0 : data.userState,
        medicalInstitution: {
            ...medicalInstitutionDetail,
            memberImage: userIcon,
            apsUrl: `https://${medicalInstitutionDetail.clientCertificate.domain}`,
        },
        clientCertificate: medicalInstitutionDetail.clientCertificate,
        medicalInstitutionMembers: parseMembers(medicalInstitutionMembers),
        refetchMedicalInstitutionMembers,
        onResendWelcomeMailButtonClick: handleResendWelcomeMail,
    };
    return React.createElement(Component, { ...props });
};
