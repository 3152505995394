import React from "react";
import styled from "styled-components";
const StyledList = styled.ul `
  display: flex;
  flex-direction: column;
`;
export function List(props) {
    return React.createElement(StyledList, null, props.children);
}
const StyledListItem = styled.li `
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 58px;
  border-bottom: 1px solid ${(props) => props.theme.colors.primaryBorder};
  > * {
    min-width: 180px;
  }
`;
export function ListItem(props) {
    return React.createElement(StyledListItem, null, props.children);
}
