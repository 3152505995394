import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import error from "../../assets/images/error.png";
import { Typography } from "../atoms/Typography";
/**
 * 存在しないページ
 */
const Container = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    margin-top: 80px;
  }
`;
const TitleWrapper = styled.div `
  margin-bottom: 50px;
`;
const StyledLink = styled(Link) `
  cursor: pointer;
  width: 100%;
  max-width: 400px;
  height: 44px;
  background-color: ${(props) => props.theme.colors.brand};
  text-decoration: none;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.secondaryText};
`;
export function NotFoundPage() {
    return (React.createElement(Container, null,
        React.createElement("img", { src: error, width: "300", height: "300", alt: "error" }),
        React.createElement(TitleWrapper, null,
            React.createElement(Typography, { size: 6, bold: true }, "\u5B58\u5728\u3057\u306A\u3044\u30DA\u30FC\u30B8\u3067\u3059")),
        React.createElement(StyledLink, { to: "" },
            React.createElement(Typography, { size: 2, bold: true }, "TOP\u30DA\u30FC\u30B8\u306B\u3082\u3069\u308B"))));
}
