import React, { useState, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import logo from "../../assets/images/cureapp.png";
import menu from "../../assets/icons/menu.svg";
import { HamburgerMenu } from "./HamburgerMenu";
import { hexToRGB } from "../../utils/style";
import { Typography } from "../atoms/Typography";
import { useOnClickOutside } from "../../hooks/UseOnClickOutside";
const Container = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 50px;
  border-bottom: 2px solid ${(props) => hexToRGB(props.theme.colors.brand, 0.4)};
  background-color: ${(props) => props.theme.colors.primaryBackground};
`;
const Left = styled.div `
  display: flex;
  align-items: center;
`;
const LogoWrapper = styled.div `
  cursor: pointer;
`;
const MessageWrapper = styled.div `
  padding: 8px;
  margin-left: 20px;
  overflow: hidden;
  background-color: ${(props) => hexToRGB(props.theme.colors.secondaryBackground, 0.08)};
  border-radius: 4px;
`;
const Right = styled.div `
  display: flex;
  align-items: center;
`;
const UserBlock = styled.div `
  overflow: hidden;
  padding-right: 18px;
  border-right: 1px solid
    ${(props) => hexToRGB(props.theme.colors.primaryBorder, 0.5)};
`;
const NameWrapper = styled.div `
  p {
    text-align: right;
  }
`;
const MedicalInstitutionNameWrapper = styled(NameWrapper) `
  && {
    margin-bottom: 6px;
    opacity: 0.7;
  }
`;
const IconArea = styled.div `
  position: relative;
`;
const IconWrapper = styled.div `
  margin-left: 20px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
export const Header = ({ message, user, medicalInstitutionName, version, logoutClick, }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    const menuRef = useRef(null);
    useOnClickOutside(menuRef, () => setIsMenuOpen(false));
    return (React.createElement(Container, null,
        React.createElement(Left, null,
            React.createElement(Link, { to: "/" },
                React.createElement(LogoWrapper, null,
                    React.createElement("img", { src: logo, width: "22", height: "22", alt: "logo" }))),
            version && (React.createElement(MessageWrapper, null,
                React.createElement(Typography, { size: 1 },
                    "version: ",
                    version))),
            message && (React.createElement(MessageWrapper, null,
                React.createElement(Typography, { size: 1, withEllipsis: true }, message)))),
        user.name && (React.createElement(Right, null,
            React.createElement(UserBlock, null,
                React.createElement(MedicalInstitutionNameWrapper, null,
                    React.createElement(Typography, { size: 1 }, medicalInstitutionName)),
                React.createElement(NameWrapper, null,
                    React.createElement(Typography, { size: 1, bold: true }, user.name))),
            React.createElement(IconArea, { ref: menuRef },
                React.createElement(IconWrapper, { onClick: () => toggleMenu() },
                    React.createElement("img", { src: menu, alt: "menu" })),
                React.createElement(HamburgerMenu, { user: user, isOpen: isMenuOpen, toggleMenu: toggleMenu, onLogoutClick: logoutClick }))))));
};
Header.defaultProps = {
    hasNotice: false,
};
