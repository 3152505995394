import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { hexToRGB } from "../../utils/style";
const Header = styled.div `
  padding: 18px;
  display: flex;
  justify-content: space-between;
  background: ${(props) => hexToRGB(props.theme.colors.secondaryBackground, 0.1)};
  cursor: pointer;
`;
const Title = styled.h2 `
  font-size: 18px;
  font-weight: bold;
`;
const Arrow = styled.div `
  font-size: 18px;
`;
const Items = styled.div `
  ${({ isOpen }) => !isOpen && `display: none;`}
`;
export function Accordion({ title, children }) {
    const [isOpen, setIsOpen] = useState(false);
    const toggleVisibility = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen, setIsOpen]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, { onClick: toggleVisibility },
            React.createElement(Title, null, title),
            React.createElement(Arrow, null, isOpen ? (React.createElement(FontAwesomeIcon, { icon: "chevron-up" })) : (React.createElement(FontAwesomeIcon, { icon: "chevron-down" })))),
        React.createElement(Items, { isOpen: isOpen }, children)));
}
