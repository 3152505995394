import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { PageBody } from "../PageBody";
import { Typography } from "../../atoms/Typography";
import { Label } from "../../atoms/Label";
import { Input } from "../../atoms/Input";
import { InputDate } from "../../atoms/InputDate";
import { SelectList, SelectListItem } from "../../atoms/Select";
import { Button } from "../../atoms/Button";
import { IconTextButton } from "../../atoms/IconTextButton";
import { defaultTheme } from "../../themes";
import dayjs from "dayjs";
library.add(faPlusCircle);
library.add(faTimes);
const JsonInputTextArea = styled.textarea `
  background-color: ${(props) => props.theme.colors.inputFieldBackground};
  border: 1px solid ${(props) => props.theme.colors.primaryBorder};
  border-radius: 6px;
  color: ${(props) => props.theme.colors.primaryText};
  padding: 0 40px;
  width: 100%;
  height: 100px;
`;
const ContentWrapper = styled.div `
  padding: 0 40px;
  clear: both;
  ${({ firstChild }) => firstChild && "margin-top: 15px;"}
`;
const InputWrapper = styled.div `
  width: ${(props) => props.width || "30%"};
  float: left;
  margin: 0 30px 5px 0;
`;
const InputGroupWrapper = styled.div `
  margin: 6px 0 10px 0;
  clear: both;
`;
const ButtonGroupWrapper = styled.div `
  margin: 6px 0 10px 0;
  width: auto;
  display: inline-block;
`;
const CreateButtonWrapper = styled.div `
  width: 214px;
  height: 44px;
  float: right;
  margin-bottom: 70px;
`;
export function CreateJsonDeliveryOrderPage({ orderJson, name, onNameChange, postalCode, onPostalCodeChange, phoneNumber, onPhoneNumberChange, address, onAddressChange, deliveryDate, onDeliveryDateChange, deliveryTimeCode, onDeliveryTimeCodeChange, orderClassCode, onOrderClassCodeChange, productsItem, validationErrors, onCancelButtonClick, onSubmitClick, onAddProductButtonClick, isSubmitButtonDisable, handleJsonFormat, isClickedSubmitButton, }) {
    var _a, _b, _c;
    const isDeliveryDateValid = validationErrors.deliveryDate
        ? validationErrors.deliveryDate.isValid
        : true;
    const isDeliveryTimeCodeValid = validationErrors.deliveryTimeCode
        ? validationErrors.deliveryTimeCode.isValid
        : true;
    const isOrderClassCodeValid = validationErrors.orderClassCode
        ? validationErrors.orderClassCode.isValid
        : true;
    return (React.createElement(React.Fragment, null,
        React.createElement(PageBody, { title: "追加配送情報の入力", buttonTitle: "\u30EA\u30BB\u30C3\u30C8", buttonType: "danger", buttonOutline: true, buttonIcon: React.createElement(FontAwesomeIcon, { icon: faTimes, color: defaultTheme.colors.caution }), onButtonClick: () => onCancelButtonClick() },
            React.createElement(React.Fragment, null,
                React.createElement(ContentWrapper, null,
                    React.createElement(InputWrapper, { width: "45%" },
                        React.createElement(Label, { text: "Jsonフォーマットの入力", margin: "0 0 7px 0" }),
                        React.createElement(JsonInputTextArea, { value: orderJson, onChange: handleJsonFormat }))),
                React.createElement(ContentWrapper, { firstChild: true },
                    React.createElement(InputGroupWrapper, null,
                        React.createElement(InputWrapper, { width: "45%" },
                            React.createElement(Label, { text: "宛名", margin: "0 0 7px 0" }),
                            React.createElement(Input, { value: name, error: !validationErrors.name.isValid, errorMessages: validationErrors.name.message, onChange: onNameChange }))),
                    React.createElement(InputGroupWrapper, null,
                        React.createElement(InputWrapper, { width: "18%" },
                            React.createElement(Label, { text: "郵便番号", emphasis: "note", note: "\u30CF\u30A4\u30D5\u30F3\u306A\u3057", margin: "0 0 7px 0" }),
                            React.createElement(Input, { value: postalCode, error: !validationErrors.postalCode.isValid, errorMessages: validationErrors.postalCode.message, onChange: onPostalCodeChange })),
                        React.createElement(InputWrapper, { width: "75%" },
                            React.createElement(Label, { text: "配送先住所", margin: "0 0 7px 0" }),
                            React.createElement(Input, { value: address, error: !validationErrors.address.isValid, errorMessages: validationErrors.address.message, onChange: onAddressChange }))),
                    React.createElement(InputGroupWrapper, null,
                        React.createElement(InputWrapper, { width: "30%" },
                            React.createElement(Label, { text: "電話番号", emphasis: "note", note: "\u30CF\u30A4\u30D5\u30F3\u306A\u3057", margin: "0 0 7px 0" }),
                            React.createElement(Input, { value: phoneNumber, error: !validationErrors.phoneNumber.isValid, errorMessages: validationErrors.phoneNumber.message, onChange: onPhoneNumberChange })),
                        React.createElement(InputWrapper, { width: "15%" },
                            React.createElement(Label, { text: "配送指定日", emphasis: "optional", margin: "0 0 7px 0" }),
                            React.createElement(InputDate, { value: deliveryDate, error: !isDeliveryDateValid, errorMessages: (_a = validationErrors.deliveryDate) === null || _a === void 0 ? void 0 : _a.message, onChange: onDeliveryDateChange, min: dayjs(new Date()).format("YYYY-MM-DD") })),
                        React.createElement(InputWrapper, { width: "15%" },
                            React.createElement(Label, { text: "配送時間帯", emphasis: "optional", margin: "0 0 7px 0" }),
                            React.createElement(SelectList, { placeholder: "", value: deliveryTimeCode, error: !isDeliveryTimeCodeValid, errorMessages: (_b = validationErrors.deliveryTimeCode) === null || _b === void 0 ? void 0 : _b.message, onValueChange: onDeliveryTimeCodeChange, items: [
                                    React.createElement(SelectListItem, { value: "YT0000", key: 0 },
                                        React.createElement(Typography, { size: 2 }, "\u6307\u5B9A\u306A\u3057")),
                                    React.createElement(SelectListItem, { value: "YT0812", key: 1 },
                                        React.createElement(Typography, { size: 2 }, "\u5348\u5F8C\u4E2D")),
                                    React.createElement(SelectListItem, { value: "YT1416", key: 2 },
                                        React.createElement(Typography, { size: 2 }, "14\uFF1A00 \uFF5E 16\uFF1A00")),
                                    React.createElement(SelectListItem, { value: "YT1618", key: 3 },
                                        React.createElement(Typography, { size: 2 }, "16\uFF1A00 \uFF5E 18\uFF1A00")),
                                    React.createElement(SelectListItem, { value: "YT1820", key: 4 },
                                        React.createElement(Typography, { size: 2 }, "18\uFF1A00 \uFF5E 20\uFF1A00")),
                                    React.createElement(SelectListItem, { value: "YT2021", key: 5 },
                                        React.createElement(Typography, { size: 2 }, "19\uFF1A00 \uFF5E 21\uFF1A00")),
                                ] })),
                        React.createElement(InputWrapper, { width: "25%" },
                            React.createElement(Label, { text: "注文クラス", emphasis: "optional", margin: "0 0 7px 0" }),
                            React.createElement(SelectList, { placeholder: "", value: orderClassCode, error: !isOrderClassCodeValid, errorMessages: (_c = validationErrors.orderClassCode) === null || _c === void 0 ? void 0 : _c.message, onValueChange: onOrderClassCodeChange, items: [
                                    React.createElement(SelectListItem, { value: "MEDICAL_INSTITUTION_SHIPPING", key: 0 },
                                        React.createElement(Typography, { size: 2 }, "\u901A\u5E38\u51FA\u8377\uFF08\u533B\u7642\u6A5F\u95A2\uFF09")),
                                    React.createElement(SelectListItem, { value: "PATIENT_SHIPPING", key: 1 },
                                        React.createElement(Typography, { size: 2 }, "\u901A\u5E38\u51FA\u8377\uFF08\u60A3\u8005\uFF09")),
                                    React.createElement(SelectListItem, { value: "MEDICAL_INSTITUTION_REPLACEMENT_SHIPPING", key: 2 },
                                        React.createElement(Typography, { size: 2 }, "\u4EE3\u66FF\u54C1\u51FA\u8377\uFF08\u533B\u7642\u6A5F\u95A2\uFF09")),
                                    React.createElement(SelectListItem, { value: "PATIENT_REPLACEMENT_SHIPPING", key: 3 },
                                        React.createElement(Typography, { size: 2 }, "\u4EE3\u66FF\u54C1\u51FA\u8377\uFF08\u60A3\u8005\uFF09")),
                                ] })))),
                React.createElement(ContentWrapper, null,
                    React.createElement(InputGroupWrapper, null,
                        React.createElement(Label, { text: "配送物", margin: "0 0 7px 0" }),
                        productsItem)),
                React.createElement(ContentWrapper, null,
                    React.createElement(ButtonGroupWrapper, null,
                        React.createElement(IconTextButton, { icon: React.createElement(FontAwesomeIcon, { icon: "plus-circle" }), text: "配送物追加", onButtonClick: onAddProductButtonClick }))),
                React.createElement(ContentWrapper, null,
                    React.createElement(CreateButtonWrapper, null,
                        React.createElement(Button, { onClick: onSubmitClick, buttonType: "primary", disabled: isSubmitButtonDisable },
                            React.createElement(Typography, { size: 2, bold: true }, isClickedSubmitButton ? "発送処理中..." : "この内容で発送"))))))));
}
