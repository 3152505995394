import { useCallback, useEffect } from "react";
import { Auth } from "@aws-amplify/auth";
import { Hub } from "@aws-amplify/core";
import { login, logout } from "../graphql/local-mutations";
export const useAuthentication = () => {
    const loginSuccess = useCallback(() => {
        Auth.currentUserInfo().then((user) => {
            var _a;
            if (user) {
                login((_a = user.attributes) !== null && _a !== void 0 ? _a : {});
            }
        });
    }, []);
    useEffect(() => {
        Hub.listen("auth", ({ payload: { event, data } }) => {
            switch (event) {
                case "signIn":
                    loginSuccess();
                    break;
                case "signOut":
                    logout();
                    break;
                case "customOAuthState":
                    if (!data) {
                        break;
                    }
                    const customState = JSON.parse(decodeURIComponent(data));
                    if (customState.redirectTo) {
                        location.replace(customState.redirectTo);
                    }
                    break;
            }
        });
        loginSuccess();
    }, [loginSuccess]);
};
