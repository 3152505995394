import { InMemoryCache, makeVar } from "@apollo/client";
import { initialState as initialModalState } from "./model/modal";
import { initialState as initialUserState } from "./model/user";
import { initialState as initialAppState } from "./model/app";
import { offsetLimitPagination, paginationForDeliveryOrder, } from "../graphql/utils";
export const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                scUsers: offsetLimitPagination(["keyword", "order"]),
                htUsers: offsetLimitPagination(["keyword", "order"]),
                medicalInstitutions: offsetLimitPagination(["keyword", "order"]),
                deliveryOrders: paginationForDeliveryOrder(["keywords", "order"]),
                deliveryOrdersWithKeywords: paginationForDeliveryOrder([
                    "keywords",
                    "order",
                ]),
                modalState: {
                    read() {
                        return modalsVar();
                    },
                },
                userState: {
                    read() {
                        return userVar();
                    },
                },
                appState: {
                    read() {
                        return appVar();
                    },
                },
            },
        },
    },
});
export const modalsVar = makeVar(initialModalState);
export const userVar = makeVar(initialUserState);
export const appVar = makeVar(initialAppState);
