import React, { useState, useEffect, } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { useValidation, useHandleError } from "../../../hooks";
import { defaultCreateDeliveryOrderFields } from "../../../graphql/utils";
import { CREATE_ORDER_DELIVERY } from "../../../graphql/mutations";
import { CreateJsonDeliveryOrderPage as Component, } from "../../../components/pages/deliveryOrder/CreateJsonDeliveryOrderPage";
import { ProductItem } from "../../../components/atoms/ProductItem";
import { GET_DELIVERY_ORDERS, GET_PRODUCTS } from "../../../graphql/queries";
import { GQLDeliveryTimeCodes } from "../../../graphql/utils";
export const CreateJsonDeliveryOrderPage = () => {
    // @ts-ignore idが存在するのに「ない」と怒られるので
    const { handleError } = useHandleError();
    const history = useHistory();
    const location = useLocation();
    const [isDisable, setIsDisable] = useState(true);
    const [isClickedSubmitButton, setIsClickedSubmitButton] = useState(false);
    const [orderJson, setOrderJson] = useState("");
    // check value is not empty, null, undefined
    const isExistValue = (value) => {
        if (value !== undefined && value !== null && value !== "") {
            return true;
        }
        return false;
    };
    // set default value for 宛名, 郵便番号, 配送先住所, 郵便番号, 電話番号
    let hasDefaultValue = false;
    const numberPattern = /[^a-zA-Z0-9]/g;
    const defaultDeliveryOrderFieldsValue = Object.assign({}, defaultCreateDeliveryOrderFields);
    if (location.state) {
        // when moving from another page to this page
        // check form validation
        if (location.state.prevPageId) {
            hasDefaultValue = true;
        }
        if (isExistValue(location.state.name)) {
            defaultDeliveryOrderFieldsValue.name = location.state.name || "";
        }
        if (isExistValue(location.state.phoneNumber)) {
            defaultDeliveryOrderFieldsValue.phoneNumber =
                location.state.phoneNumber.replace(numberPattern, "") || "";
        }
        if (isExistValue(location.state.postalCode)) {
            defaultDeliveryOrderFieldsValue.postalCode =
                location.state.postalCode.replace(numberPattern, "") || "";
        }
        if (isExistValue(location.state.address)) {
            defaultDeliveryOrderFieldsValue.address = location.state.address || "";
        }
    }
    const [productItemIndex, setProductItemIndex] = useState(1);
    const [currentDeliveryOrder, setCurrentDeliveryOrder,] = useState(defaultDeliveryOrderFieldsValue);
    // product items
    const [products, setProducts] = useState([
        { id: productItemIndex, productCode: "", quantity: undefined },
    ]);
    // get product list
    const { data: getProducts } = useQuery(GET_PRODUCTS);
    const [createDeliveryOrder, { error: createDeliveryError },] = useMutation(CREATE_ORDER_DELIVERY, {
        update(cache, createDeliveryOrderData) {
            if (!createDeliveryOrderData ||
                !createDeliveryOrderData.data ||
                !createDeliveryOrderData.data.createDeliveryOrder) {
                handleError("Mutation response undefined: createDeliveryOrder");
                return;
            }
            const deliveryOrderData = cache.readQuery({
                query: GET_DELIVERY_ORDERS,
            });
            if (!deliveryOrderData) {
                handleError("Cache data undefined: deliveryOrderData");
                return;
            }
        },
    });
    useHandleError(createDeliveryError);
    // validation
    const { errors: validationErrors, isFormValid, setTouched, resetState, } = useValidation({
        name: {
            rules: {
                inputRequired: true,
                maxLength: 100,
            },
            value: currentDeliveryOrder.name,
        },
        address: {
            rules: {
                inputRequired: true,
                maxLength: 100,
            },
            value: currentDeliveryOrder.address,
        },
        postalCode: {
            rules: {
                inputRequired: true,
                isNumeric: true,
                specificLength: 7,
            },
            value: currentDeliveryOrder.postalCode,
        },
        phoneNumber: {
            rules: {
                inputRequired: true,
                isNumeric: true,
                minLength: 10,
                maxLength: 11,
            },
            value: currentDeliveryOrder.phoneNumber,
        },
        deliveryDate: {
            rules: {
                checkDateSetValidity: true,
            },
            value: currentDeliveryOrder.deliveryDate,
        },
        deliveryTimeCode: {
            rules: {},
            value: currentDeliveryOrder.deliveryTimeCode,
        },
        orderClassCode: {
            rules: {},
            value: currentDeliveryOrder.orderClassCode,
        },
    }, [currentDeliveryOrder], hasDefaultValue);
    // gt product list for 配送物 select box
    const productsForSelectList = getProducts
        ? getProducts.products.map((product) => {
            const item = {
                code: product.code,
                name: product.name,
                description: product.description,
            };
            return item;
        })
        : [];
    const updateDeliveryOrder = (item, value) => setCurrentDeliveryOrder({ ...currentDeliveryOrder, [item]: value });
    const handleNameChange = (event) => {
        setTouched("name");
        updateDeliveryOrder("name", event.target.value);
    };
    const handlePostalCodeChange = (event) => {
        setTouched("postalCode");
        updateDeliveryOrder("postalCode", event.target.value);
    };
    const handlePhoneNumberChange = (event) => {
        setTouched("phoneNumber");
        updateDeliveryOrder("phoneNumber", event.target.value);
    };
    const handleAddressChange = (event) => {
        setTouched("address");
        updateDeliveryOrder("address", event.target.value);
    };
    const handleDeliveryDateChange = (event) => {
        setTouched("deliveryDate");
        updateDeliveryOrder("deliveryDate", event.target.value);
    };
    const handleDeliveryTimeCodeChange = (value) => {
        setTouched("deliveryTimeCode");
        updateDeliveryOrder("deliveryTimeCode", value);
    };
    const handleOrderClassCodeChange = (value) => {
        setTouched("orderClassCode");
        updateDeliveryOrder("orderClassCode", value);
    };
    const checkProductsItemValid = (items) => {
        // not product items
        if (items.length === 0) {
            return true;
        }
        // check duplicate
        const productCodeArr = items.map((item) => item.productCode);
        const isDuplicate = productCodeArr.some((item, index) => {
            return productCodeArr.indexOf(item) != index;
        });
        // check valid
        const valid = items.some((item) => {
            return (item.productCode === undefined ||
                item.productCode === "" ||
                isDuplicate ||
                item.quantity === undefined);
        });
        return valid;
    };
    const handleProductCodeChange = (value, dataId) => {
        const productItem = [...products];
        const index = Number(dataId);
        productItem.find((x) => x.id === index).productCode = value.toString();
        // add productCode to product list
        setProducts(productItem);
        const productList = products.map((product) => {
            return {
                productCode: product.productCode,
                quantity: product.quantity,
            };
        });
        // set product to delivery order
        setCurrentDeliveryOrder({
            ...currentDeliveryOrder,
            ["products"]: productList,
        });
        // check validate for 配送物
        setIsDisable(checkProductsItemValid(products));
    };
    const handleQuantityChange = (event) => {
        const productItem = [...products];
        const dataId = Number(event.currentTarget.dataset.id);
        // add quantity value to product list
        productItem.find((x) => x.id === dataId).quantity =
            Number(event.target.value) >= 1 ? Number(event.target.value) : undefined;
        setProducts(productItem);
        const productList = products.map((product) => {
            return {
                productCode: product.productCode,
                quantity: product.quantity,
            };
        });
        // set product to delivery order
        setCurrentDeliveryOrder({
            ...currentDeliveryOrder,
            ["products"]: productList,
        });
        // check validate for 配送物
        setIsDisable(checkProductsItemValid(products));
    };
    const saveJsonItems = (json) => {
        setCurrentDeliveryOrder({
            ...currentDeliveryOrder,
            name: json.name,
            postalCode: json.postalCode,
            phoneNumber: json.phoneNumber,
            address: json.address,
            deliveryDate: json.deliveryDate,
            deliveryTimeCode: json.deliveryTimeCode,
            orderClassCode: json.orderClassCode,
            products: json.products,
        });
        const newProducts = json.products.map((p, index) => {
            return {
                id: index,
                productCode: p.productCode,
                quantity: p.quantity,
            };
        });
        setProducts(newProducts);
        products.map((product, index) => {
            productItemsElement.push(React.createElement(ProductItem, { key: index, number: index, products: productsForSelectList, productCode: product.productCode, quantity: product.quantity, onProductCodeChange: handleProductCodeChange, onQuantityChange: handleQuantityChange, onDeleteRowClick: handleDeleteProductRowClick }));
        });
        setIsDisable(checkProductsItemValid(newProducts));
    };
    const handleJsonFormat = (e) => {
        const json = JSON.parse(`${e.target.value}`);
        setOrderJson(e.target.value);
        saveJsonItems(json);
    };
    // 配送物追加
    const productItemsElement = [];
    // delete 配送物追加
    const handleDeleteProductRowClick = (dataId) => {
        const items = [];
        products.find((x) => {
            if (x.id !== dataId) {
                items.push(x);
            }
        });
        setProducts(items);
        const productList = items.map((item) => {
            return {
                productCode: item.productCode,
                quantity: item.quantity,
            };
        });
        // set product to delivery order
        setCurrentDeliveryOrder({
            ...currentDeliveryOrder,
            ["products"]: productList,
        });
        // check validate
        setIsDisable(checkProductsItemValid(items));
    };
    // render 配送物追加
    products.forEach((product) => {
        productItemsElement.push(React.createElement(ProductItem, { key: product.id, number: product.id, products: productsForSelectList, productCode: product.productCode, quantity: product.quantity, onProductCodeChange: handleProductCodeChange, onQuantityChange: handleQuantityChange, onDeleteRowClick: handleDeleteProductRowClick }));
    });
    // handle 中止 button click
    const handleCancelClick = async () => {
        setCurrentDeliveryOrder(defaultDeliveryOrderFieldsValue);
        setProducts([
            { id: productItemIndex, productCode: "", quantity: undefined },
        ]);
        setOrderJson("");
        resetState();
    };
    // handle 配送物追加 button click
    const handleAddProductItemClick = async () => {
        const values = [...products];
        const newProductItemIndex = productItemIndex + 1;
        setProductItemIndex(newProductItemIndex);
        values.push({
            id: newProductItemIndex,
            productCode: "",
            quantity: undefined,
        });
        setProducts(values);
        setIsDisable(true);
    };
    // handle この内容で発送 button click
    const handleSubmit = async () => {
        try {
            const deliveryOrderFields = Object.keys(currentDeliveryOrder);
            deliveryOrderFields.forEach((key) => setTouched(key));
            if (isFormValid) {
                setIsClickedSubmitButton(true);
                const variables = {
                    input: {
                        name: currentDeliveryOrder.name,
                        postalCode: currentDeliveryOrder.postalCode,
                        phoneNumber: currentDeliveryOrder.phoneNumber,
                        address: currentDeliveryOrder.address,
                        deliveryDate: currentDeliveryOrder.deliveryDate &&
                            dayjs(currentDeliveryOrder.deliveryDate).isValid()
                            ? dayjs(currentDeliveryOrder.deliveryDate).format("YYYY-MM-DD")
                            : null,
                        deliveryTimeCode: GQLDeliveryTimeCodes[currentDeliveryOrder.deliveryTimeCode],
                        orderClassCode: currentDeliveryOrder.orderClassCode,
                        products: currentDeliveryOrder.products,
                    },
                };
                // Mutation request
                const { data } = await createDeliveryOrder({ variables });
                if (!data || !data.createDeliveryOrder) {
                    handleError("Mutation response undefined: createDeliveryOrder");
                    return;
                }
                // redirect to complete pageFv
                const id = data.createDeliveryOrder.id;
                history.push({
                    pathname: `/deliveryOrder/complete/${id}/${currentDeliveryOrder.orderClassCode}`,
                });
            }
        }
        catch (err) {
            handleError(err);
        }
    };
    useEffect(() => {
        setIsDisable(isClickedSubmitButton || isDisable);
    }, [isClickedSubmitButton, isDisable]);
    const props = {
        orderJson,
        name: currentDeliveryOrder.name,
        postalCode: currentDeliveryOrder.postalCode,
        phoneNumber: currentDeliveryOrder.phoneNumber,
        address: currentDeliveryOrder.address,
        deliveryDate: currentDeliveryOrder.deliveryDate,
        deliveryTimeCode: currentDeliveryOrder.deliveryTimeCode,
        orderClassCode: currentDeliveryOrder.orderClassCode,
        productsItem: productItemsElement,
        validationErrors,
        isSubmitButtonDisable: isDisable,
        onNameChange: handleNameChange,
        onPostalCodeChange: handlePostalCodeChange,
        onPhoneNumberChange: handlePhoneNumberChange,
        onAddressChange: handleAddressChange,
        onDeliveryDateChange: handleDeliveryDateChange,
        onDeliveryTimeCodeChange: handleDeliveryTimeCodeChange,
        onOrderClassCodeChange: handleOrderClassCodeChange,
        onCancelButtonClick: handleCancelClick,
        onAddProductButtonClick: handleAddProductItemClick,
        onSubmitClick: handleSubmit,
        handleJsonFormat,
        isClickedSubmitButton,
    };
    return React.createElement(Component, { ...props });
};
