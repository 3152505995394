import { useQuery, useReactiveVar } from "@apollo/client";
import Auth from "@aws-amplify/auth";
import React, { useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AuthContainerTmp as Component } from "../../../components/pages/auth/AuthContainerTmp";
import { GET_APP_STATE } from "../../../graphql/queries";
import { userVar } from "../../../cache";
import { NotFoundPage } from "../../../components/pages/NotFoundPage";
import { AWS_AUTH_IDP } from "../../../settings";
// ログイン周りの各画面の遷移を管理するコンポネント
export function AuthContainer() {
    const history = useHistory();
    const location = useLocation();
    const userState = useReactiveVar(userVar);
    const { data: appData } = useQuery(GET_APP_STATE);
    const { isNotSameSubDomain } = (appData === null || appData === void 0 ? void 0 : appData.appState) || {
        isNotSameSubDomain: false,
    };
    const handleSSOLogin = useCallback(() => {
        var _a;
        const from = (_a = location.state) === null || _a === void 0 ? void 0 : _a.from;
        const customState = from
            ? {
                redirectTo: encodeURIComponent(from.pathname + from.search + from.hash),
            }
            : undefined;
        Auth.federatedSignIn({
            customProvider: AWS_AUTH_IDP,
            // ログイン成功後のリダイレクト先をcustomState経由で渡し、Hubのlistenerで受け取る
            customState: JSON.stringify(customState),
        });
    }, [location]);
    useEffect(() => {
        var _a, _b;
        if (userState.isLoggedIn) {
            history.replace((_b = (_a = location.state) === null || _a === void 0 ? void 0 : _a.from) !== null && _b !== void 0 ? _b : "/");
        }
    }, [userState, history, location]);
    if (isNotSameSubDomain) {
        return React.createElement(NotFoundPage, null);
    }
    return React.createElement(Component, { onSSOLoginClick: handleSSOLogin });
}
