import React from "react";
import { useLocation } from "react-router-dom";
import { PageBody } from "../../../components/pages/PageBody";
import { Typography } from "../../../components/atoms/Typography";
import styled from "styled-components";
export const PrescriptionCancelErrorPage = () => {
    const location = useLocation();
    const { message, prescriptionCode } = location.state;
    const RowWrapper = styled.div `
    min-height: 58px;
    padding: 10px 5px 10px 0;
    ${({ theme }) => `border-bottom: 1px solid ${theme.colors.primaryBorder};`}
    p {
      margin-top: 10px;
    }
  `;
    const ContentWrapper = styled.div `
    padding: 0 40px;
    clear: both;
  `;
    return (React.createElement(React.Fragment, null,
        React.createElement(PageBody, { title: "処方キャンセルに失敗しました" },
            React.createElement(ContentWrapper, null,
                React.createElement(RowWrapper, null,
                    React.createElement(Typography, { size: 2 },
                        "\u30A8\u30E9\u30FC\u30E1\u30C3\u30BB\u30FC\u30B8: ",
                        message),
                    React.createElement(Typography, { size: 2 },
                        " \u51E6\u65B9\u30B3\u30FC\u30C9: ",
                        prescriptionCode),
                    React.createElement(Typography, { size: 2 }, "\u4E0A\u8A18\u60C5\u5831\u3068\u4E00\u7DD2\u306BDTF\u30C1\u30FC\u30E0\u3078\u3054\u9023\u7D61\u304F\u3060\u3055\u3044"))))));
};
