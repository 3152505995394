import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";
import { hexToRGB } from "../../utils/style";
const StyledCSSTransition = styled(CSSTransition) `
  &.modal-enter {
    opacity: 0;
  }
  &.modal-enter-active {
    opacity: 1;
    transition: all 300ms ease-in-out;
  }
  &.modal-exit {
    opacity: 1;
  }
  &.modal-exit-active {
    opacity: 0;
    transition: all 300ms ease-in-out;
  }
`;
const BackDrop = styled.div `
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 100;
  position: fixed;
  align-items: center;
  touch-action: none;
  justify-content: center;
  background-color: ${(props) => hexToRGB(props.theme.colors.primaryShadow, 0.4)};
  ${(props) => props.blurBackground && `backdrop-filter: blur(8px)`};
`;
const ModalWrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.primaryBackground};
  overflow: hidden;
`;
const modalRoot = document.getElementById("modal-root");
Modal.defaultProps = {
    isOpen: false,
    blurBackground: false,
};
export function Modal({ isOpen, blurBackground, children, className }) {
    const modalUI = (React.createElement(StyledCSSTransition, { in: isOpen, timeout: 500, classNames: "modal", unmountOnExit: true },
        React.createElement(BackDrop, { blurBackground: blurBackground },
            React.createElement(ModalWrapper, { className: className }, children))));
    return ReactDOM.createPortal(modalUI, modalRoot);
}
