import React, { useCallback } from "react";
import styled from "styled-components";
import { PageBody } from "../PageBody";
import { DataListBody, DataListBodyItem, DataListHeader, DataListHeaderItem, DataListRow, DataTable, SwitchHeaderOrder, } from "../../atoms/Table";
import { SearchInput } from "../../atoms/SearchInput";
import { Typography } from "../../atoms/Typography";
import { LoadMoreButton } from "../../atoms/LoadMoreButton";
const Container = styled.div `
  display: flex;
  flex-direction: column;
  padding: 30px 40px;
  height: 100%;
`;
export function MedicalInstitutionPage({ medicalInstitutions, searchValue, order, onSearchClick, onResetClick, onSortClick, onRowClick, onLoadMoreClick, }) {
    const sortClick = useCallback((value) => {
        onSortClick(SwitchHeaderOrder(value, order));
    }, [onSortClick, order]);
    const header = (React.createElement(React.Fragment, null,
        React.createElement(DataListHeaderItem, { onSort: () => sortClick("id"), order: (order === null || order === void 0 ? void 0 : order.field) === "id" ? order.value : undefined, align: "center" },
            React.createElement(Typography, { size: 1, bold: true }, "ID")),
        React.createElement(DataListHeaderItem, { onSort: () => sortClick("name"), order: (order === null || order === void 0 ? void 0 : order.field) === "name" ? order.value : undefined },
            React.createElement(Typography, { size: 1, bold: true }, "\u533B\u7642\u6A5F\u95A2\u540D")),
        React.createElement(DataListHeaderItem, { onSort: () => sortClick("prefecture"), order: (order === null || order === void 0 ? void 0 : order.field) === "prefecture" ? order.value : undefined },
            React.createElement(Typography, { size: 1, bold: true }, "\u90FD\u9053\u5E9C\u770C")),
        React.createElement(DataListHeaderItem, { onSort: () => sortClick("phoneNumber"), order: (order === null || order === void 0 ? void 0 : order.field) === "phoneNumber" ? order.value : undefined },
            React.createElement(Typography, { size: 1, bold: true }, "\u96FB\u8A71\u756A\u53F7"))));
    const searchInput = (React.createElement(SearchInput, { value: searchValue, placeholder: "キーワードを入力", onSearch: onSearchClick, onReset: onResetClick }));
    return (React.createElement(PageBody, { title: "\u533B\u7642\u6A5F\u95A2\u4E00\u89A7", searchInput: searchInput },
        React.createElement(Container, null,
            React.createElement(DataTable, null,
                React.createElement(DataListHeader, { row: header, flexRatio: [3, 5, 2, 2] }),
                React.createElement(DataListBody, { rows: medicalInstitutions.map((medicalInstitution, index) => (React.createElement(DataListRow, { flexRatio: [3, 5, 2, 2], key: index, onRowClick: () => onRowClick(medicalInstitution.id) },
                        React.createElement(DataListBodyItem, null,
                            React.createElement(Typography, { size: 2, withEllipsis: true }, medicalInstitution.id)),
                        React.createElement(DataListBodyItem, null,
                            React.createElement(Typography, { size: 2, withEllipsis: true }, medicalInstitution.name)),
                        React.createElement(DataListBodyItem, null,
                            React.createElement(Typography, { size: 2, withEllipsis: true }, medicalInstitution.prefecture)),
                        React.createElement(DataListBodyItem, null,
                            React.createElement(Typography, { size: 2 }, medicalInstitution.phoneNumber))))) })),
            React.createElement(LoadMoreButton, { onClick: () => onLoadMoreClick(medicalInstitutions.length) }))));
}
