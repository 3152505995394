import React from "react";
import styled from "styled-components";
import { Typography } from "../atoms/Typography";
import { MenuItem } from "../atoms/MenuItem";
const Menu = styled.div `
  display: flex;
  align-items: center;
`;
// const ButtonWrapper = styled.div`
//   width: 156px;
//   height: 44px;
//   margin-left: auto;
//   margin-right: 20px;
// `;
export const MenuList = ({ isAdminMenuVisible = false }) => {
    return (React.createElement(Menu, null,
        isAdminMenuVisible && (React.createElement(React.Fragment, null,
            React.createElement(MenuItem, { to: "/patient" },
                React.createElement(Typography, { bold: true, size: 2 }, "\u51E6\u65B9\u60C5\u5831(SC)")),
            React.createElement(MenuItem, { to: "/patient_ht" },
                React.createElement(Typography, { bold: true, size: 2 }, "\u51E6\u65B9\u60C5\u5831(HT)")))),
        React.createElement(MenuItem, { to: "/medicalInstitution" },
            React.createElement(Typography, { bold: true, size: 2 }, "\u533B\u7642\u6A5F\u95A2\u60C5\u5831"))));
};
