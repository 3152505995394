import { initialState, } from "../../cache/model/modal";
export const createModalMutations = (modalVar) => {
    const initializeModalState = () => modalVar(initialState);
    const openModal = (modalName) => modalVar({ ...modalVar(), [modalName]: { isOpen: true } });
    const openSecretCodeModal = (name, title, secretCode) => modalVar({
        ...modalVar(),
        secretCode: { isOpen: true, name, title, secretCode },
    });
    const openPrescriptionCompletedModal = (prescriptionId) => modalVar({
        ...modalVar(),
        prescriptionCompleted: { isOpen: true, prescriptionId },
    });
    const openExtendPrescriptionCodeExpiryCompletedModal = (name, expiry) => modalVar({
        ...modalVar(),
        extendPrescriptionCodeExpiryCompleted: {
            isOpen: true,
            name,
            expiry,
        },
    });
    const openResetModal = (type) => modalVar({ ...modalVar(), reset: { isOpen: true, type } });
    const closeModal = (modalName) => modalVar({ ...modalVar(), [modalName]: { isOpen: false } });
    const openConfirmUsedCodeModal = (supportUrl) => modalVar({ ...modalVar(), confirmUsedCode: { isOpen: true, supportUrl } });
    return {
        initializeModalState,
        openModal,
        openSecretCodeModal,
        openPrescriptionCompletedModal,
        openExtendPrescriptionCodeExpiryCompletedModal,
        openResetModal,
        closeModal,
        openConfirmUsedCodeModal,
    };
};
