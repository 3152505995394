import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import styled from "styled-components";
import clearIcon from "../../assets/icons/clear.png";
import { hexToRGB } from "../../utils/style";
const Container = styled.div `
  display: flex;
  height: 50px;
`;
const InputWrapper = styled.div `
  display: flex;
  position: relative;
  width: 100%;
`;
const StyledSearchInput = styled.input `
  background-color: ${(props) => props.theme.colors.primaryBackground};
  color: ${(props) => props.theme.colors.primaryText};
  font-size: ${(props) => props.theme.fontSize.medium};
  ::placeholder {
    opacity: 0.5;
  }
  border: 2px solid ${(props) => hexToRGB(props.theme.colors.brand, 0.4)};
  border-right: none;
  border-radius: 6px 0 0 6px;
  outline: 0;
  width: 100%;
  padding-left: 46px;
`;
const Icon = styled.span `
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;
const SearchIcon = styled(Icon) `
  left: 20px;
`;
const CloseIcon = styled(Icon) `
  right: 20px;
  cursor: pointer;
`;
const SearchButton = styled.button `
  width: 100px;
  font-size: 16px;
  background-color: ${(props) => props.disabled
    ? props.theme.colors.disabledBackground
    : props.theme.colors.brand};
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.secondaryText};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
`;
export function SearchInput(props) {
    const { value, clear, placeholder, onSearch, onReset, disabled = false, } = props;
    const [searchInputValue, setSearchInputValue] = useState(value || "");
    const handleChange = (event) => {
        setSearchInputValue(event.target.value);
    };
    const onClear = () => {
        setSearchInputValue("");
        onReset();
    };
    const searchText = () => {
        onSearch(searchInputValue);
    };
    return (React.createElement(Container, null,
        React.createElement(InputWrapper, null,
            React.createElement(SearchIcon, null, React.createElement(FontAwesomeIcon, { icon: "search", color: "#10626C" })),
            React.createElement(StyledSearchInput, { type: "text", value: searchInputValue, placeholder: placeholder, onChange: handleChange }),
            clear && (React.createElement(CloseIcon, { onClick: onClear },
                React.createElement("img", { src: clearIcon, width: "20", height: "20", alt: "clear_text" })))),
        React.createElement(SearchButton, { disabled: !searchInputValue.length || disabled, onClick: () => searchText() }, "\u691C\u7D22")));
}
SearchInput.defaultProps = {
    value: "",
    placeholder: "",
    error: false,
    clear: true,
};
