import React from "react";
import styled from "styled-components";
import { Typography } from "./Typography";
const StyledButton = styled.div `
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${(props) => props.buttonType === "danger"
    ? props.theme.colors.caution
    : props.theme.colors.brand};
  ${(props) => props.outline &&
    `
    border: 3px solid;
    border-color: ${props.buttonType === "danger"
        ? props.theme.colors.caution
        : props.theme.colors.brand};
    border-radius: 6px;
    padding: 10px 15px;
  `}
`;
const IconWrapper = styled.div `
  margin-right: 6px;
`;
export function IconTextButton(props) {
    const { icon, text, outline, buttonType, onButtonClick } = props;
    return (React.createElement(StyledButton, { onClick: onButtonClick, outline: outline, buttonType: buttonType },
        React.createElement(IconWrapper, null, icon),
        text && (React.createElement(Typography, { size: 2, bold: true }, text))));
}
