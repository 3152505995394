const defaultModal = {
    isOpen: false,
};
export const initialState = {
    createPrescription: { ...defaultModal },
    cancelCreatePrescription: { ...defaultModal },
    editPrescription: { ...defaultModal },
    cancelEditPrescription: { ...defaultModal },
    deletePrescription: { ...defaultModal },
    sessionExpired: { ...defaultModal },
    prescriptionCompleted: {
        ...defaultModal,
        prescriptionId: null,
    },
    createMember: { ...defaultModal },
    cancelCreateMember: { ...defaultModal },
    editMember: { ...defaultModal },
    cancelEditMember: { ...defaultModal },
    suspendMember: { ...defaultModal },
    secretCode: {
        isOpen: false,
        title: null,
        name: null,
        secretCode: null,
    },
    reset: {
        type: null,
        ...defaultModal,
    },
    editPrescriptionItems: { ...defaultModal },
    cancelEditPrescriptionItems: { ...defaultModal },
    editMedicalInstitution: { ...defaultModal },
    cancelEditMedicalInstitution: { ...defaultModal },
    extendPrescriptionCodeExpiry: { ...defaultModal },
    extendPrescriptionCodeExpiryCompleted: {
        ...defaultModal,
        name: "",
        expiry: null,
    },
    error: { ...defaultModal },
    faqAndHelp: { ...defaultModal },
    confirmUsedCode: { ...defaultModal, supportUrl: "" },
    confirmInput: { ...defaultModal },
    confirmVerifyCode: { ...defaultModal },
    confirmClientCertificatePassword: { ...defaultModal },
};
