import React from "react";
import styled from "styled-components";
const ErrorMessageWrapper = styled.div `
  width: 300px;
  height: 15px;
  font-size: ${(props) => props.theme.fontSize.xsmall};
  line-height: 22px;
  color: ${(props) => props.theme.colors.caution};
`;
export const ErrorMessage = ({ errorMessage }) => (React.createElement(ErrorMessageWrapper, null, errorMessage || ""));
