import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { closeModal } from "../../../graphql/local-mutations";
import { PUBLISH_HT_VERIFY_CODE } from "../../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { PublishVerifyCodePage as Component } from "../../../components/pages/patientHt/PublishVerifyCodePage";
import { GET_MODAL_STATE } from "../../../graphql/queries";
import { useCheckUserRole } from "../../../hooks/useCheckUserRole";
export const PublishVerifyCodePage = () => {
    const history = useHistory();
    const location = useLocation();
    const htPatient = location.state;
    useCheckUserRole();
    const [verifyCode, setVerifyCode] = useState("");
    const [permanent, setPermanent] = useState(false);
    const [snackBar, setSnackBar] = useState(null);
    const showOKSnackBar = (message) => {
        setSnackBar({
            type: "ok",
            message,
            isOpen: true,
            close() {
                setSnackBar(null);
            },
        });
    };
    const showErrorSnackBar = (message) => {
        setSnackBar({
            type: "error",
            message,
            isOpen: true,
            close() {
                setSnackBar(null);
            },
        });
    };
    const [publishVerifyCode, { loading }] = useMutation(PUBLISH_HT_VERIFY_CODE, {
        onCompleted: () => {
            showOKSnackBar("確認番号の発行しました。");
            closeModal("confirmVerifyCode");
        },
        onError: () => {
            showErrorSnackBar("確認番号の発行に失敗しました。DTFのチームにお問い合わせください。");
            closeModal("confirmVerifyCode");
        },
    });
    const { data: modalData } = useQuery(GET_MODAL_STATE);
    const confirmVerifyCodeModalOpened = (modalData === null || modalData === void 0 ? void 0 : modalData.modalState.confirmVerifyCode.isOpen) || false;
    const onCancelClick = () => history.push(`/patient_ht/${htPatient.id}`);
    const onPublishVerifyCodeClick = () => {
        publishVerifyCode({
            variables: {
                input: {
                    id: htPatient.id,
                    code: verifyCode,
                    permanent,
                },
            },
        });
    };
    return (React.createElement(Component, { setVerifyCode: setVerifyCode, setPermanent: setPermanent, verifyCode: verifyCode, permanent: permanent, snackBar: snackBar, isLoading: loading, confirmVerifyCodeModalOpened: confirmVerifyCodeModalOpened, onCancelClick: onCancelClick, onPublishVerifyCodeClick: onPublishVerifyCodeClick }));
};
