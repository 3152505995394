import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
const StyledMenuItem = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: 20px;
  margin-right: 20px;
  height: 60px;
  cursor: pointer;
  a {
    text-decoration: none;
    color: inherit;
    height: 100%;
    display: flex;
    align-items: center;
  }
`;
const StyledNavLink = styled(NavLink) `
  &.active {
    &&::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    border-bottom: 4px solid ${(props) => props.theme.colors.brand};
}
`;
export function MenuItem({ to, children }) {
    return (React.createElement(StyledMenuItem, null,
        React.createElement(StyledNavLink, { to: to, isActive: (match, location) => {
                if (!match) {
                    return false;
                }
                // サブパスまで一致させたいため、NavLinkのexact属性を外し、isActiveでActive表示を任せる
                // 処方一覧ページ（Topページ）以外ののタブを表示時、処方一覧ページがActiveにならないため、且つ
                // 処方詳細ページ表示時、処方一覧ページがActiveになるため、以下の条件式で制御する
                return (to !== "/" ||
                    to === location.pathname ||
                    location.pathname.startsWith("/patient"));
            } }, children)));
}
