import React from "react";
import { useHistory } from "react-router-dom";
import { useListDataWithLoadMore } from "../../../hooks";
import { GET_HT_USERS } from "../../../graphql/queries";
import { parseHtPatients } from "../../../graphql/utils";
import { PatientHtPage as Component, } from "../../../components/pages/patientHt/PatientHtPage";
import { useCheckUserRole } from "../../../hooks/useCheckUserRole";
export const PatientHtPage = () => {
    const history = useHistory();
    useCheckUserRole();
    const { data, variables: { keyword, order }, handlers: { onSearchClick, onResetClick, onSortClick, onLoadMoreClick }, } = useListDataWithLoadMore(GET_HT_USERS, "htUsers", "処方情報は全て表示されています");
    if (!(data === null || data === void 0 ? void 0 : data.htUsers)) {
        return null;
    }
    const props = {
        patients: parseHtPatients(data.htUsers),
        searchValue: keyword,
        order,
        onSearchClick,
        onResetClick,
        onSortClick,
        onRowClick: (patientId) => history.push(`patient_ht/${patientId}`),
        onLoadMoreClick,
    };
    return React.createElement(Component, { ...props });
};
