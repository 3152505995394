import dayjs from "dayjs";
import scAppIcon from "../assets/images/pdts/sc.png";
import htAppIcon from "../assets/images/pdts/ht.png";
import userIcon from "../assets/images/users/sample.png";
/**
 * cache utilities
 */
// reference: https://github.com/apollographql/apollo-client/blob/b2ad938f66a99c8e6b4bd5303f930f29d50e4b62/src/utilities/policies/pagination.ts#L24
// A basic field policy that uses options.args.{offset,limit} to splice
// the incoming data into the existing array. If your arguments are called
// something different (like args.{start,count}), feel free to copy/paste
// this implementation and make the appropriate changes.
// → changed from {offset,limit} to {skip,take}
export function offsetLimitPagination(keyArgs = false) {
    return {
        keyArgs,
        merge(existing, incoming, { args }) {
            const merged = existing ? existing.slice(0) : [];
            if (args) {
                // Assume an skip of 0 if args.skip omitted.
                const { skip = 0 } = args;
                if (skip === 0) {
                    return incoming;
                }
                else {
                    for (let i = 0; i < incoming.length; ++i) {
                        merged[skip + i] = incoming[i];
                    }
                }
            }
            return merged;
        },
    };
}
export function paginationForDeliveryOrder(keyArgs = false) {
    return {
        keyArgs,
        merge(existing, incoming, { args }) {
            const merged = existing ? existing.slice(0) : [];
            if (args) {
                // Assume an skip of 0 if args.skip omitted.
                const { offset = 0 } = args;
                if (offset === 0) {
                    return incoming;
                }
                else {
                    for (let i = 0; i < incoming.length; ++i) {
                        merged[offset + i] = incoming[i];
                    }
                }
            }
            return merged;
        },
    };
}
/**
 * Patient
 */
// PARSERS
// Parse GQL response into the component props
export const parseScPatients = (users) => {
    return users.map((user) => ({
        id: user.id,
        name: `${user.lastName || ""} ${user.firstName || ""}`,
        appName: user.appName,
        // FIXME: hardcoded
        appImage: scAppIcon,
        prescriptionCode: user.prescriptionCode,
        prescribedAt: (user.prescribedAt && dayjs(user.prescribedAt).format("YYYY/MM/DD")) ||
            "",
    }));
};
// Parse GQL response into the component props
export const parseHtPatients = (users) => {
    return users.map((user) => ({
        id: user.id,
        name: `${user.nickname || ""}`,
        appName: user.appName,
        // FIXME: hardcoded
        appImage: htAppIcon,
        prescriptionCode: user.prescriptionCode,
        prescribedAt: (user.prescribedAt && dayjs(user.prescribedAt).format("YYYY/MM/DD")) ||
            "",
    }));
};
// Parse GQL response into the component props
export const parsePatientSc = (user) => {
    return {
        patientId: user.id,
        name: `${user.lastName || ""} ${user.firstName || ""}`,
        // FIXME: hardcoded
        memberImage: userIcon,
        appName: user.appName,
        phoneNumber: user.phoneNumber,
        email: user.email,
        coCheckerSerial: user.coCheckerSerial,
        birthday: (user.birthday && dayjs(user.birthday).format("YYYY/MM/DD")) || "",
        registeredAt: dayjs(user.registeredAt).format("YYYY/MM/DD") || "",
        prescriptionCode: user.prescriptionCode,
        prescriptionCodeId: user.prescriptionCodeId,
        prescribedAt: (user.prescribedAt && dayjs(user.prescribedAt).format("YYYY/MM/DD")) ||
            "",
        medicalInstitutionId: user.medicalInstitutionId,
        medicalInstitutionName: user.medicalInstitutionName,
        prescription: user.prescription,
    };
};
export const parsePatientHt = (htUser) => {
    return {
        patientId: htUser.id,
        name: `${htUser.nickname || ""}`,
        // FIXME: hardcoded
        memberImage: userIcon,
        appName: htUser.appName,
        email: htUser.email,
        birthday: (htUser.birthday && dayjs(htUser.birthday).format("YYYY/MM/DD")) || "",
        prescriptionCode: htUser.prescriptionCode,
        prescriptionCodeId: htUser.prescriptionCodeId,
        prescribedAt: (htUser.prescribedAt &&
            dayjs(htUser.prescribedAt).format("YYYY/MM/DD")) ||
            "",
        medicalInstitutionId: htUser.medicalInstitutionId,
        medicalInstitutionName: htUser.medicalInstitutionName,
        prescription: htUser.prescription,
    };
};
export const parseDeliveryOrder = (deliveryOrder) => {
    return {
        id: deliveryOrder.id,
        name: deliveryOrder.name,
        postalCode: deliveryOrder.postalCode,
        address: deliveryOrder.address,
        phoneNumber: deliveryOrder.phoneNumber,
        deliveryDate: deliveryOrder.deliveryDate
            ? dayjs(deliveryOrder.deliveryDate).format("YYYY/MM/DD")
            : "",
        deliveryTime: deliveryOrder.deliveryTime,
        deliveryProducts: deliveryOrder.deliveryProducts,
        orderClass: deliveryOrder.orderClass.name,
    };
};
// Default state
export const defaultCreateDeliveryOrderFields = {
    name: "",
    postalCode: "",
    phoneNumber: "",
    address: "",
    deliveryDate: "",
    deliveryTimeCode: "YT0000",
    orderClassCode: "MEDICAL_INSTITUTION_SHIPPING",
    products: [
        {
            productCode: "",
            quantity: undefined,
        },
    ],
};
export const defaultUploadPdtDocumentFields = {
    name: "",
    version: 1,
    enableFlag: true,
    file: {
        lastModified: 20210101,
        name: "",
        size: 1,
        type: "",
        slice: () => new Blob(),
        stream: () => new ReadableStream(),
        text: async () => "",
        arrayBuffer: async () => new ArrayBuffer(1),
        webkitRelativePath: "",
    },
};
export const defaultCreatePdtDocumentFields = {
    name: "",
    version: 1,
    enableFlag: true,
    url: "",
};
export const parseDeliveryOrders = (deliveryOrders) => {
    return deliveryOrders.map((deliveryOrder) => ({
        id: deliveryOrder.id,
        name: deliveryOrder.name,
        // FIXME: hardcoded
        image: userIcon,
        postalCode: deliveryOrder.postalCode,
        address: deliveryOrder.address,
        phoneNumber: deliveryOrder.phoneNumber,
        deliveryDate: deliveryOrder.deliveryDate
            ? dayjs(deliveryOrder.deliveryDate).format("YYYY/MM/DD")
            : "",
        deliveryTime: deliveryOrder.deliveryTime,
        deliveryHistory: deliveryOrder.deliveryHistory.filter((deliveryHistory) => {
            return (deliveryHistory.serialNumber !== undefined &&
                deliveryHistory.serialNumber !== null &&
                deliveryHistory.serialNumber !== "");
        }),
        deliveryProducts: deliveryOrder.deliveryProducts,
    }));
};
export const parseDeliveryOrderDetail = (deliveryOrder) => {
    return {
        id: deliveryOrder.id,
        name: deliveryOrder.name,
        // FIXME: hardcoded
        image: userIcon,
        postalCode: deliveryOrder.postalCode,
        address: deliveryOrder.address,
        phoneNumber: deliveryOrder.phoneNumber,
        deliveryDate: deliveryOrder.deliveryDate
            ? dayjs(deliveryOrder.deliveryDate).format("YYYY/MM/DD")
            : "",
        deliveryTime: deliveryOrder.deliveryTime,
        deliveryHistory: deliveryOrder.deliveryHistory,
        deliveryProducts: deliveryOrder.deliveryProducts,
    };
};
export const GQLDeliveryTimeCodes = {
    YT0000: "YT0000" /* GQLDeliveryTimeCode.Yt0000 */,
    YT0812: "YT0812" /* GQLDeliveryTimeCode.Yt0812 */,
    YT1416: "YT1416" /* GQLDeliveryTimeCode.Yt1416 */,
    YT1618: "YT1618" /* GQLDeliveryTimeCode.Yt1618 */,
    YT1820: "YT1820" /* GQLDeliveryTimeCode.Yt1820 */,
    YT2021: "YT2021" /* GQLDeliveryTimeCode.Yt2021 */,
};
export const GQLDeliveryOrderClasses = {
    MEDICAL_INSTITUTION_REPLACEMENT_SHIPPING: "MEDICAL_INSTITUTION_REPLACEMENT_SHIPPING" /* GQLOrderClassCode.MedicalInstitutionReplacementShipping */,
    PATIENT_REPLACEMENT_SHIPPING: "PATIENT_REPLACEMENT_SHIPPING" /* GQLOrderClassCode.PatientReplacementShipping */,
};
/**
 * Medical Institution Member
 */
const positionLabels = {
    ["DOCTOR" /* GQLMedicalInstitutionMemberPosition.Doctor */]: "医師",
    ["NURSE" /* GQLMedicalInstitutionMemberPosition.Nurse */]: "看護師",
    ["STAFF" /* GQLMedicalInstitutionMemberPosition.Staff */]: "スタッフ",
};
const roleLabels = {
    ["ADMIN" /* GQLMedicalInstitutionMemberRole.Admin */]: "管理者",
    ["STAFF" /* GQLMedicalInstitutionMemberRole.Staff */]: "スタッフ",
};
const statusLables = {
    ["ENABLED" /* GQLMedicalInstitutionMemberStatus.Enabled */]: "有効",
    ["DISABLED" /* GQLMedicalInstitutionMemberStatus.Disabled */]: "停止中",
    ["INVITED" /* GQLMedicalInstitutionMemberStatus.Invited */]: "招待中",
    ["SECRETCODE_REISSUED" /* GQLMedicalInstitutionMemberStatus.SecretcodeReissued */]: "再発行済み",
};
const parsePositionsToLabel = (positions) => positions.map((position) => positionLabels[position]);
const parseRolesToLabel = (roles) => roles.map((role) => roleLabels[role]);
export const parseMembers = (GQLMedicalInstitutionMembers) => GQLMedicalInstitutionMembers.map((GQLMember) => ({
    id: GQLMember.id,
    name: GQLMember.name,
    position: parsePositionsToLabel(GQLMember.position),
    role: parseRolesToLabel(GQLMember.role),
    status: statusLables[GQLMember.status],
}));
/**
 * Prescription
 */
export const parsePrescriptionCancel = (prescription) => {
    return {
        id: prescription.id,
        name: prescription.name,
        birthday: (prescription.birthday &&
            dayjs(prescription.birthday).format("YYYY/MM/DD")) ||
            "",
        createdAt: (prescription.createdAt &&
            dayjs(prescription.createdAt).format("YYYY/MM/DD hh:mm")) ||
            "",
        appName: prescription.appName,
        medicalInstitutionName: prescription.medicalInstitutionName,
        codeId: prescription.codeId,
        code: prescription.code,
        codeStatus: prescription.codeStatus,
    };
};
