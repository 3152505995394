import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { DEBUG } from "../settings";
import { errorLogger } from "../utils/logger";
/**
 * エラーを監視してエラーが発生したらエラーページに飛ばす
 * @param observingError 監視するApolloErrorもしくはstring
 * @param hookDeps エラーがあればエラーページに飛ばす処理をhookさせるdependencies
 * @return handleError エラーページに飛ばす処理を発生させる関数
 */
export const useHandleError = (observingError = undefined, hookDeps = []) => {
    const history = useHistory();
    // TODO: ApolloErrorはerrorLinkに任せることにしたのでuseHandleErrorにApolloErrorを渡している箇所をなくすように修正する
    const handleError = (errorMessage) => {
        if (DEBUG === "*" && errorMessage !== undefined) {
            errorLogger(`${errorMessage}`);
        }
    };
    useEffect(() => {
        if (observingError) {
            handleError();
        }
    }, [observingError, history, ...hookDeps]);
    return { handleError };
};
