import React from "react";
import styled from "styled-components";
import { Button } from "../../atoms/Button";
import { Typography } from "../../atoms/Typography";
import { AuthBase } from "./AuthBase";
const Container = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 410px;
`;
const LoginButtonWrapper = styled.div `
  margin: 40px 0 22px 0;
  height: 44px;
`;
export function AuthContainerTmp({ onSSOLoginClick }) {
    return (React.createElement(AuthBase, { hasBackButton: false },
        React.createElement(Container, null,
            React.createElement(LoginButtonWrapper, null,
                React.createElement(Button, { buttonType: "primary", onClick: onSSOLoginClick },
                    React.createElement(Typography, { size: 2 }, "Azure AD\u3067\u30ED\u30B0\u30A4\u30F3"))))));
}
