import React from "react";
import styled from "styled-components";
import { StatusLabelMap } from "../../../utils/prescription";
import { hexToRGB } from "../../../utils/style";
import okIcon from "../../../assets/icons/ok.png";
import errorIcon from "../../../assets/icons/alert.png";
import { Button } from "../../atoms/Button";
import { List, ListItem } from "../../atoms/List";
import { SearchInput } from "../../atoms/SearchInput";
import { Snackbar } from "../../atoms/Snackbar";
import { Typography } from "../../atoms/Typography";
import { PageBody } from "../PageBody";
const Container = styled.div `
  display: flex;
  flex-direction: column;
  padding: 30px 40px;
  height: 100%;
`;
const ContentWrapper = styled.div `
  padding: 0 40px;
`;
const Inner = styled.div `
  padding-bottom: 20px;
  display: flex;
  ${(props) => hexToRGB(props.theme.colors.primaryBorder, 0.5)};
`;
const LeftBlock = styled.div `
  flex: 1;
  padding-right: 40px;
`;
const MemberName = styled.div `
  display: flex;
  align-items: center;
`;
const RightBlock = styled.div `
  flex: 1;
  padding-left: 40px;
`;
const PaddingRightWrapper = styled.div `
  width: 100%;
  padding-right: 20px;
`;
const SubmitButtonWrapper = styled.div `
  width: 100%;
  height: 50px;
  max-width: 250px;
  margin: 10px auto 0;
`;
const TextRightTypography = (props) => (React.createElement(PaddingRightWrapper, null,
    React.createElement(Typography, { textRight: true, size: 2 }, props.children)));
export function PrescriptionCancelPage({ isCancelPrescription, isLoading, prescription, snackBar, onSearchClick, onSubmitClick, }) {
    const searchInput = (React.createElement(SearchInput, { clear: false, placeholder: "\u51E6\u65B9\u30B3\u30FC\u30C9\u3092\u5165\u529B", onSearch: onSearchClick, onReset: () => { }, disabled: isCancelPrescription }));
    return (React.createElement(React.Fragment, null,
        React.createElement(PageBody, { title: "\u51E6\u65B9\u30AD\u30E3\u30F3\u30BB\u30EB", searchInput: searchInput },
            React.createElement(Container, null, typeof prescription === "undefined" ? (React.createElement(Typography, null, "\u53F3\u4E0A\u306E\u30C6\u30AD\u30B9\u30C8\u30D5\u30A3\u30FC\u30EB\u30C9\u306B\u51E6\u65B9\u30B3\u30FC\u30C9\u3092\u5165\u529B\u3057\u3001\u691C\u7D22\u30DC\u30BF\u30F3\u3092\u30AF\u30EA\u30C3\u30AF\u3057\u3066\u304F\u3060\u3055\u3044\u3002")) : isLoading ? (React.createElement(Typography, null, "\u51E6\u65B9\u60C5\u5831\u53D6\u5F97\u4E2D...")) : prescription === null ? (React.createElement(Typography, null, "\u8A72\u5F53\u306E\u51E6\u65B9\u30B3\u30FC\u30C9\u306F\u898B\u3064\u304B\u308A\u307E\u305B\u3093\u3067\u3057\u305F\u3002")) : (React.createElement(React.Fragment, null,
                React.createElement(Inner, null,
                    React.createElement(Typography, { size: 2 }, "\u4EE5\u4E0B\u306E\u51E6\u65B9\u60C5\u5831\u3067\u9593\u9055\u3044\u306A\u3044\u304B\u78BA\u8A8D\u3057\u3001\u300C\u51E6\u65B9\u5F37\u5236\u30AD\u30E3\u30F3\u30BB\u30EB\u300D\u30DC\u30BF\u30F3\u3092\u30AF\u30EA\u30C3\u30AF\u3057\u3066\u304F\u3060\u3055\u3044\u3002")),
                React.createElement(ContentWrapper, null,
                    React.createElement(Inner, null,
                        React.createElement(LeftBlock, null,
                            React.createElement(MemberName, null,
                                React.createElement(Typography, { size: 5, breakWord: true }, prescription.name))))),
                React.createElement(ContentWrapper, null,
                    React.createElement(Inner, null,
                        React.createElement(LeftBlock, null,
                            React.createElement(List, null,
                                React.createElement(ListItem, null,
                                    React.createElement(Typography, { bold: true, size: 1 }, "id"),
                                    React.createElement(TextRightTypography, null, prescription.id)),
                                React.createElement(ListItem, null,
                                    React.createElement(Typography, { bold: true, size: 1 }, "\u51E6\u65B9\u65E5\u6642"),
                                    React.createElement(TextRightTypography, null, prescription.createdAt)),
                                React.createElement(ListItem, null,
                                    React.createElement(Typography, { bold: true, size: 1 }, "\u30A2\u30D7\u30EA\u540D"),
                                    React.createElement(TextRightTypography, null, prescription.appName)),
                                React.createElement(ListItem, null,
                                    React.createElement(Typography, { bold: true, size: 1 }, "\u51E6\u65B9\u533B\u7642\u6A5F\u95A2"),
                                    React.createElement(TextRightTypography, null, prescription.medicalInstitutionName)))),
                        React.createElement(RightBlock, null,
                            React.createElement(List, null,
                                React.createElement(ListItem, null,
                                    React.createElement(Typography, { bold: true, size: 1 }, "\u751F\u5E74\u6708\u65E5"),
                                    React.createElement(TextRightTypography, null, prescription.birthday)),
                                React.createElement(ListItem, null,
                                    React.createElement(Typography, { bold: true, size: 1 }, "\u51E6\u65B9\u30B3\u30FC\u30C9"),
                                    React.createElement(TextRightTypography, null, prescription.code)),
                                React.createElement(ListItem, null,
                                    React.createElement(Typography, { bold: true, size: 1 }, "\u51E6\u65B9\u30B3\u30FC\u30C9ID"),
                                    React.createElement(TextRightTypography, null, prescription.codeId)),
                                React.createElement(ListItem, null,
                                    React.createElement(Typography, { bold: true, size: 1 }, "\u51E6\u65B9\u30B3\u30FC\u30C9\u30B9\u30C6\u30FC\u30BF\u30B9"),
                                    React.createElement(TextRightTypography, null, StatusLabelMap[prescription.codeStatus])))))),
                React.createElement(SubmitButtonWrapper, null,
                    React.createElement(Button, { onClick: () => {
                            onSubmitClick(prescription.code);
                        }, buttonType: "primary", disabled: isCancelPrescription },
                        React.createElement(Typography, { size: 2 }, isCancelPrescription
                            ? "処方キャンセル中..."
                            : "処方強制キャンセル"))))))),
        !!snackBar && (React.createElement(Snackbar, { isOpen: snackBar.isOpen, icon: React.createElement("img", { src: snackBar.type === "ok" ? okIcon : errorIcon, width: "18", height: "18", alt: snackBar.type }), text: snackBar.message, close: snackBar.close }))));
}
