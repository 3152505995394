import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { PageBody } from "../PageBody";
import { Input } from "../../atoms/Input";
import { Label } from "../../atoms/Label";
import { Typography } from "../../atoms/Typography";
import { Snackbar } from "../../atoms/Snackbar";
import { Button } from "../../atoms/Button";
import { ConfirmModal } from "../../../containers/organisms/modal/ConfirmModal";
import { openModal, closeModal } from "../../../graphql/local-mutations";
import { defaultTheme } from "../../themes";
import okIcon from "../../../assets/icons/ok.png";
import errorIcon from "../../../assets/icons/alert.png";
const ContentWrapper = styled.div `
  padding: 20px 40px;
  display: flex;
  flex-wrap: wrap;
`;
const ItemWrapper = styled.div `
  ${(props) => props.marginBottom && `margin-bottom: ${props.marginBottom}`};
  ${(props) => props.width && `width: ${props.width}`};
  padding: 0 30px 5px 0;
`;
const ButtonWrapper = styled.div `
  ${(props) => props.marginTop && `margin-top: ${props.marginTop}`};
  width: ${(props) => props.width || "214px"};
  height: 44px;
`;
export function UpdateEmailAddressPage({ htPatient, onUpdateEmailAddressClick, processing, validationErrors, proposedEmail, setProposedEmail, isFormValid, snackBar = null, onCancelButtonClick, isOpen, }) {
    return (React.createElement(React.Fragment, null,
        React.createElement(PageBody, { title: "メールアドレス変更", buttonTitle: "\u4E2D\u6B62", buttonType: "danger", buttonOutline: true, buttonIcon: React.createElement(FontAwesomeIcon, { icon: faTimes, color: defaultTheme.colors.caution }), onButtonClick: () => {
                onCancelButtonClick();
            } },
            React.createElement(React.Fragment, null,
                React.createElement(ContentWrapper, null,
                    React.createElement(ItemWrapper, { width: "100%" },
                        React.createElement(Label, { text: "変更前メールアドレス", margin: "0 0 7px 0" }),
                        React.createElement("p", null, htPatient.email || "なし"))),
                React.createElement(ContentWrapper, null,
                    React.createElement(Label, { text: "変更後メールアドレス", margin: "0 0 7px 0" }),
                    React.createElement(ItemWrapper, { width: "100%" },
                        React.createElement(Input, { onChange: (e) => setProposedEmail(e.target.value), value: proposedEmail, error: !validationErrors.proposedEmail.isValid, errorMessages: validationErrors.proposedEmail.message })),
                    React.createElement(ButtonWrapper, { width: "334px" },
                        React.createElement(Button, { onClick: () => openModal("confirmInput"), disabled: processing || !isFormValid, buttonType: "primary" },
                            React.createElement(Typography, { size: 1 }, "\u5909\u66F4")))))),
        React.createElement(ConfirmModal, { isOpen: isOpen, value: proposedEmail, isLoading: processing, title: "メールアドレスを以下に変更してもよろしいですか？", actionLabel: "\u5909\u66F4", cancelLabel: "\u30AD\u30E3\u30F3\u30BB\u30EB", onChangeClick: () => onUpdateEmailAddressClick(proposedEmail), closeModal: () => closeModal("confirmInput") }),
        !!snackBar && (React.createElement(Snackbar, { isOpen: snackBar.isOpen, icon: React.createElement("img", { src: snackBar.type === "ok" ? okIcon : errorIcon, width: "18", height: "18", alt: snackBar.type }), text: snackBar.message, close: snackBar.close }))));
}
