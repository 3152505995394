import React, { useCallback } from "react";
import styled from "styled-components";
import { PageBody } from "../PageBody";
import { DataListBody, DataListBodyItem, DataListHeader, DataListHeaderItem, DataListRow, DataTable, SwitchHeaderOrder, } from "../../atoms/Table";
import { SearchInput } from "../../atoms/SearchInput";
import { Typography } from "../../atoms/Typography";
import { LoadMoreButton } from "../../atoms/LoadMoreButton";
const Container = styled.div `
  display: flex;
  flex-direction: column;
  padding: 30px 40px;
  height: 100%;
`;
const NameWrapper = styled.div `
  max-width: 100%;
`;
const AppWrapper = styled.div `
  display: flex;
  align-items: center;
  max-width: 204px;
  img {
    flex-shrink: 0;
    margin-right: 8px;
  }
`;
export function PatientScPage({ patients, searchValue, order, onSearchClick, onResetClick, onSortClick, onRowClick, onLoadMoreClick, }) {
    const sortClick = useCallback((value) => {
        onSortClick(SwitchHeaderOrder(value, order));
    }, [onSortClick, order]);
    const header = (React.createElement(React.Fragment, null,
        React.createElement(DataListHeaderItem, { onSort: () => sortClick("id"), order: (order === null || order === void 0 ? void 0 : order.field) === "id" ? order.value : undefined, align: "center" },
            React.createElement(Typography, { size: 1, bold: true }, "ID")),
        React.createElement(DataListHeaderItem, { onSort: () => sortClick("name"), order: (order === null || order === void 0 ? void 0 : order.field) === "name" ? order.value : undefined },
            React.createElement(Typography, { size: 1, bold: true }, "\u6C0F\u540D")),
        React.createElement(DataListHeaderItem, { onSort: () => sortClick("appName"), order: (order === null || order === void 0 ? void 0 : order.field) === "appName" ? order.value : undefined },
            React.createElement(Typography, { size: 1, bold: true }, "\u30A2\u30D7\u30EA\u540D")),
        React.createElement(DataListHeaderItem, { onSort: () => sortClick("prescriptionCode"), order: (order === null || order === void 0 ? void 0 : order.field) === "prescriptionCode" ? order.value : undefined },
            React.createElement(Typography, { size: 1, bold: true }, "\u51E6\u65B9\u30B3\u30FC\u30C9")),
        React.createElement(DataListHeaderItem, { onSort: () => sortClick("prescribedAt"), order: (order === null || order === void 0 ? void 0 : order.field) === "prescribedAt" ? order.value : undefined },
            React.createElement(Typography, { size: 1, bold: true }, "\u51E6\u65B9\u65E5"))));
    const searchInput = (React.createElement(SearchInput, { value: searchValue, placeholder: "キーワードを入力", onSearch: onSearchClick, onReset: onResetClick }));
    return (React.createElement(PageBody, { title: "\u51E6\u65B9\u4E00\u89A7", searchInput: searchInput },
        React.createElement(Container, null,
            React.createElement(DataTable, null,
                React.createElement(DataListHeader, { row: header, flexRatio: [2, 3, 3, 2, 1] }),
                React.createElement(DataListBody, { rows: patients.map((patient) => (React.createElement(DataListRow, { flexRatio: [2, 3, 3, 2, 1], key: patient.id, onRowClick: () => onRowClick(patient.id) },
                        React.createElement(DataListBodyItem, null,
                            React.createElement(Typography, { size: 2, withEllipsis: true }, patient.id)),
                        React.createElement(DataListBodyItem, null,
                            React.createElement(NameWrapper, null,
                                React.createElement(Typography, { size: 2, withEllipsis: true }, patient.name))),
                        React.createElement(DataListBodyItem, null,
                            React.createElement(AppWrapper, null,
                                React.createElement("img", { src: patient.appImage, width: "36", height: "36", alt: "app_logo" }),
                                React.createElement(Typography, { size: 2, withEllipsis: true }, patient.appName))),
                        React.createElement(DataListBodyItem, null,
                            React.createElement(Typography, { size: 2, withEllipsis: true }, patient.prescriptionCode)),
                        React.createElement(DataListBodyItem, null,
                            React.createElement(Typography, { size: 2, withEllipsis: true }, patient.prescribedAt))))) })),
            React.createElement(LoadMoreButton, { onClick: () => onLoadMoreClick(patients.length) }))));
}
