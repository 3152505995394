export const StatusLabelMap = {
    TENTATIVE: "仮処方",
    UNUSED: "利用開始前",
    EXPIRED: "利用期限切れ",
    USED: "利用中",
    SUSPENDED: "終了予定",
    TERMINATED: "終了",
    FULFILLED: "期間満了",
    CANCELED: "キャンセル済み",
};
