import { useState } from "react";
import Auth from "@aws-amplify/auth";
import { ApolloClient, ApolloLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { cache } from "../cache";
import { createUploadLink } from "apollo-upload-client";
import { GRAPHQL_URL, DEBUG, VERSION } from "../settings";
import { APOLLO_ERR_CODE } from "../utils/constants";
import { errorLogger } from "../utils/logger";
export const useApolloClient = () => {
    const [errorLinkStatus, setErrorLinkStatus] = useState({
        sessionExpired: false,
        notFound: false,
        forbidden: false,
        serverError: false,
        notSameSubDomain: false,
    });
    const authLink = setContext((_, { headers }) => {
        // Return the headers to the context so httpLink can read them
        return new Promise(async (resolve, reject) => {
            try {
                const session = await Auth.currentSession();
                const token = session.getIdToken();
                resolve({
                    headers: {
                        ...headers,
                        authorization: token ? `Bearer ${token.getJwtToken()}` : "",
                        "x-dtf-client-name": "cs-system-client",
                        "x-dtf-client-version": VERSION !== null && VERSION !== void 0 ? VERSION : "",
                    },
                });
            }
            catch (err) {
                reject(err);
            }
        });
    });
    const linkHandleErrorByErrorCode = (errorCode) => {
        switch (errorCode) {
            case APOLLO_ERR_CODE.UNAUTHENTICATED:
                setErrorLinkStatus({ ...errorLinkStatus, sessionExpired: true });
                return;
            case APOLLO_ERR_CODE.NOT_FOUND:
                setErrorLinkStatus({ ...errorLinkStatus, notFound: true });
                return;
            case APOLLO_ERR_CODE.FORBIDDEN:
                setErrorLinkStatus({ ...errorLinkStatus, forbidden: true });
                return;
            case APOLLO_ERR_CODE.PDT_ERROR:
            case APOLLO_ERR_CODE.INVALID_ARGS:
                setErrorLinkStatus({ ...errorLinkStatus, serverError: true });
                return;
            case APOLLO_ERR_CODE.NOT_SAME_SUBDOMAIN:
                setErrorLinkStatus({ ...errorLinkStatus, notSameSubDomain: true });
                return;
        }
    };
    /**
     * 不必要なerrorまでsentryに流したり、コンソールに表示することを避けるためのメソッドです
     * @param error {GraphQLError}
     */
    const shouldShowError = (error) => {
        var _a, _b, _c;
        const existExtensions = !!error.extensions && !!error.extensions.code;
        // 以下のエラー群はsentryに流す必要がないため、エラーを投げないようにしています。
        return (existExtensions &&
            ((_a = error.extensions) === null || _a === void 0 ? void 0 : _a.code) !== APOLLO_ERR_CODE.UNAUTHENTICATED &&
            ((_b = error.extensions) === null || _b === void 0 ? void 0 : _b.code) !== APOLLO_ERR_CODE.NOT_AUTHORIZED &&
            ((_c = error.extensions) === null || _c === void 0 ? void 0 : _c.code) !== APOLLO_ERR_CODE.NOT_AUTHENTICATED);
    };
    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (DEBUG !== "*") {
            // TODO: 以下の仕様を使って、Production時に"ApolloError"のエラーがDeveloper Tools上に出ないようにどのエラーでもignoreする
            // https://www.apollographql.com/docs/react/data/error-handling/#ignoring-errors
            // @ts-ignore
            // response.errors = null;
            return;
        }
        if (graphQLErrors) {
            graphQLErrors.map(({ extensions }) => {
                extensions &&
                    extensions.code &&
                    linkHandleErrorByErrorCode(extensions.code);
            });
            graphQLErrors.map((error) => {
                var _a;
                if (!shouldShowError(error)) {
                    return;
                }
                errorLogger(`[GraphQL Error] ${(_a = error.extensions) === null || _a === void 0 ? void 0 : _a.code}: ${error.message}`);
            });
        }
        if (networkError) {
            errorLogger(networkError);
        }
    });
    const link = ApolloLink.from([
        authLink,
        errorLink,
        createUploadLink({
            uri: GRAPHQL_URL,
        }),
    ]);
    return {
        apolloClient: new ApolloClient({
            cache,
            link,
            defaultOptions: {
                watchQuery: {
                    fetchPolicy: "cache-and-network",
                    errorPolicy: "all",
                },
            },
        }),
        errorLinkStatus,
    };
};
