import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { Input } from "./Input";
import { SelectList, SelectListItem } from "./Select";
import { Typography } from "./Typography";
import { IconTextButton } from "./IconTextButton";
library.add(faTimesCircle);
const InputWrapper = styled.div `
  width: ${(props) => props.width || "30%"};
  float: left;
  margin: 0 30px 5px 0;
`;
const InputGroupWrapper = styled.div `
  margin: 6px 0 10px 0;
  clear: both;
`;
const ButtonArea = styled.div `
  margin-left: auto;
  margin-top: 12px;
`;
export function ProductItem({ number, productCode, quantity, products, onProductCodeChange, onQuantityChange, onDeleteRowClick, }) {
    return (React.createElement(InputGroupWrapper, { key: number },
        React.createElement(InputWrapper, { width: "70%" },
            React.createElement(SelectList, { key: number, dataId: number, placeholder: "", value: productCode, onValueChange: onProductCodeChange, items: products.map((product) => (React.createElement(SelectListItem, { value: product.code, key: product.code },
                    React.createElement(Typography, { size: 2 }, `${product.name} : ${product.description}`)))) })),
        React.createElement(InputWrapper, { width: "8%" },
            React.createElement(Input, { dataId: number.toString(), value: quantity === null || quantity === void 0 ? void 0 : quantity.toString(), number: true, onChange: onQuantityChange, suffix: "個" })),
        React.createElement(InputWrapper, { width: "10%" },
            React.createElement(ButtonArea, null,
                React.createElement(IconTextButton, { icon: React.createElement(FontAwesomeIcon, { icon: "times-circle" }), onButtonClick: () => {
                        onDeleteRowClick(number);
                    } })))));
}
