import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { ClientCertificate as Component } from "../../components/organisms/ClientCertificate";
import { useLazyQuery } from "@apollo/client";
import { GET_CLIENT_CERTIFICATE } from "../../graphql/queries";
import { GET_MODAL_STATE } from "../../graphql/queries";
import { closeModal, openModal } from "../../graphql/local-mutations";
import { useSnackBar, useCheckUserRole } from "../../hooks";
export function ClientCertificate({ clientCertificate, }) {
    useCheckUserRole();
    const { snackBar, showOKSnackBar, showErrorSnackBar } = useSnackBar();
    const [password, setPassword] = useState("");
    const { data: modalData } = useQuery(GET_MODAL_STATE);
    const [getClientCertificate, { data }] = useLazyQuery(GET_CLIENT_CERTIFICATE, {
        onCompleted: () => {
            openModal("confirmClientCertificatePassword");
            setPassword(data.clientCertificate.password || "");
        },
        onError: () => {
            showErrorSnackBar("パスワードの取得に失敗しました。DTFチームにお問い合わせください。");
            closeModal("confirmClientCertificatePassword");
        },
    });
    const handleGetClientCertificatePassword = async () => {
        getClientCertificate({
            variables: {
                domain: clientCertificate.domain,
            },
        });
    };
    const handleCopyPasswordToClipboard = (text) => {
        if (!navigator.clipboard) {
            showErrorSnackBar("このブラウザはクリップボードコピーに対応していません");
            closeModal("confirmClientCertificatePassword");
            return;
        }
        navigator.clipboard.writeText(text).then(() => {
            closeModal("confirmClientCertificatePassword");
            showOKSnackBar("パスワードをクリップボードにコピーしました");
        }, () => {
            showErrorSnackBar("パスワードのコピーに失敗しました");
        });
    };
    return (React.createElement(Component, { isOpen: (modalData === null || modalData === void 0 ? void 0 : modalData.modalState.confirmClientCertificatePassword.isOpen) || false, clientCertificate: clientCertificate, snackBar: snackBar, onCancelButtonClick: () => {
            closeModal("confirmClientCertificatePassword");
        }, password: password, handleGetClientCertificatePassword: handleGetClientCertificatePassword, handleCopyPasswordToClipboard: handleCopyPasswordToClipboard }));
}
