import React, { useRef } from "react";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";
import { Title } from "../atoms/Title";
import { useOnClickOutside } from "../../hooks/UseOnClickOutside";
import { IconTextButton } from "../atoms/IconTextButton";
import { hexToRGB } from "../../utils/style";
const StyledCSSTransition = styled(CSSTransition) `
  &.menu-enter {
    opacity: 0;
  }
  &.menu-enter-active {
    opacity: 1;
    transition: all 300ms ease-in-out;
  }
  &.menu-exit {
    opacity: 1;
  }
  &.menu-exit-active {
    opacity: 0;
    transition: all 300ms ease-in-out;
  }
`;
const Container = styled.div `
  padding: 20px;
  background-color: ${(props) => props.theme.colors.pageBodyBackground};
  width: 100%;
  min-height: 100%;
`;
const StyledPageBody = styled.div `
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.primaryBackground};
  box-shadow: 0 2px 6px ${(props) => props.theme.colors.primaryShadow};
  overflow: hidden;
  min-height: 100%;
  ${(props) => props.hasBorder && `border: 1px solid ${props.theme.colors.primaryBorder}`};
  width: 100%;
`;
const Header = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 58px;
  padding: 0 40px;
  border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  position: relative;
`;
const ButtonArea = styled.div `
  margin-left: auto;
`;
const MenuList = styled.div `
  position: absolute;
  top: 48px;
  right: 20px;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid ${(props) => props.theme.colors.primaryBorder};
  background-color: ${(props) => props.theme.colors.primaryBackground};
  box-shadow: 8px 8px 8px
    ${(props) => hexToRGB(props.theme.colors.secondaryBackground, 0.1)};
`;
const SearchWrapper = styled.div `
  width: 500px;
`;
PageBody.defaultProps = {
    menuListVisible: false,
    hasBorder: false,
    hasBackground: true,
    buttonType: "primary",
    buttonOutline: false,
};
export function PageBody(props) {
    const { title, buttonTitle, buttonIcon, onButtonClick, children, menuList, menuListVisible, onHideMenu, hasBorder, hasBackground, buttonType, buttonOutline, } = props;
    const ref = useRef(null);
    useOnClickOutside(ref, () => onHideMenu());
    const Inner = (React.createElement(StyledPageBody, { hasBorder: hasBorder },
        React.createElement(Header, null,
            React.createElement(Title, { text: title }),
            props.searchInput && (React.createElement(SearchWrapper, null, props.searchInput)),
            buttonTitle && (React.createElement(ButtonArea, { ref: menuList ? ref : null },
                React.createElement(IconTextButton, { icon: buttonIcon, text: buttonTitle, buttonType: buttonType, outline: buttonOutline, onButtonClick: onButtonClick }),
                React.createElement(StyledCSSTransition, { in: menuListVisible, timeout: 300, classNames: "menu", unmountOnExit: true },
                    React.createElement(MenuList, null, menuList))))),
        children));
    return hasBackground ? React.createElement(Container, null, Inner) : Inner;
}
