import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { setNotFoundStatus, setForbiddenStatus, setServerErrorStatus, showMenuList, } from "../graphql/local-mutations";
export const useHandleServerError = () => {
    const [currentPath, setCurrentPath] = useState(undefined);
    const location = useLocation();
    useEffect(() => {
        if (currentPath === undefined) {
            setCurrentPath(location.pathname);
            return;
        }
        if (location && location.pathname !== currentPath) {
            setNotFoundStatus(false);
            setForbiddenStatus(false);
            setServerErrorStatus(false);
            showMenuList();
            setCurrentPath(location.pathname);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);
};
