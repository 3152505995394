import React, { useEffect } from "react";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";
import { Typography } from "./Typography";
import { hexToRGB } from "../../utils/style";
const StyledSnackbar = styled.div `
  height: 60px;
  width: calc(100% - 40px);
  box-shadow: 0 4px 8px
    ${(props) => hexToRGB(props.theme.colors.secondaryBackground, 0.3)};
  border-radius: 6px;
  background-color: ${(props) => props.theme.colors.secondaryBackground};
  opacity: 0.95;
  color: ${(props) => props.theme.colors.secondaryText};
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  left: 20px;
`;
const IconWrapper = styled.div `
  margin-right: 12px;
`;
const CloseWrapper = styled.div `
  margin-left: auto;
  opacity: 0.7;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const CloseIcon = styled.div `
  &&::after {
    display: inline-block;
    content: "\\00d7"; /* This will render the 'X' */
    margin-right: 3px;
  }
`;
const StyledCSSTransition = styled(CSSTransition) `
  &.snackbar-enter {
    bottom: -80px;
  }
  &.snackbar-enter-active {
    bottom: 20px;
    transition: all 500ms ease-in-out;
  }
  &.snackbar-exit {
    opacity: 1;
  }
  &.snackbar-exit-active {
    opacity: 0;
    transition: all 500ms ease-in-out;
  }
`;
export function Snackbar(props) {
    const { isOpen, close, icon, text } = props;
    useEffect(() => {
        if (!isOpen) {
            return;
        }
        const timer = setTimeout(() => {
            close();
        }, 10000);
        return () => clearTimeout(timer);
    }, [isOpen, close]);
    return (React.createElement(StyledCSSTransition, { in: isOpen, timeout: 500, classNames: "snackbar", unmountOnExit: true },
        React.createElement(StyledSnackbar, null,
            React.createElement(IconWrapper, null, icon),
            React.createElement(Typography, { size: 3, bold: true }, text),
            React.createElement(CloseWrapper, { onClick: () => close() },
                React.createElement(CloseIcon, null),
                React.createElement(Typography, { size: 1, bold: true }, "\u9589\u3058\u308B")))));
}
